
import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, FormControl,Validators } from '@angular/forms';
import { Router} from '@angular/router';
import { Http_client_Service } from '../../Service/http.service';

import { MatSnackBar } from '@angular/material/snack-bar';
import { MustMatch } from './must-match';

@Component({ selector: 'app-new-user',
 templateUrl: './new_user.html',
 styleUrls: ['./new_user.css']
 })

export class new_user_Component implements OnInit {


  registerForm = this.fb.group({
    name: ['', Validators.required],
    email: ['', [Validators.required, Validators.email]],
    password: ['', [Validators.required, Validators.minLength(6)]],
    confirmPassword: ['', Validators.required],
    acceptTerms: [false, Validators.requiredTrue]
  },{ validator: MustMatch('password', 'confirmPassword')});


  submitted = false;




    constructor(private fb : FormBuilder,private rter: Router,private hs: Http_client_Service,private _snackBar: MatSnackBar) { }

    ngOnInit() {



              }



              get f() { return this.registerForm.controls; }

              onSubmit() {
                this.submitted = true;
                console.log('this.fb',this.registerForm.value);
                if (this.registerForm.invalid) {
                    return;
                }
                else
                {
                  console.log('this.fbbbb',this.submitted);

                  this.hs.register_new_user(this.registerForm.value)
                  .subscribe((jsonData:any) => {  this.res_(jsonData);
                  },(err:any) => console.error(err),
                  );

                }


            }

            res_(data:any)
            {
              console.log('res',data);
              this.openSnackBar(data.msg,data.sucess);
              if(data.sucess == true)
              {
                this.rter.navigate(['/login']);
              }

            }


        openSnackBar(message: string, action: string) {
          this._snackBar.open(message, action, {
            duration: 2000,
            horizontalPosition: 'center',
            verticalPosition: 'top'
          });
        }


     onReset() {
          this.submitted = false;
          this.registerForm.reset();
      }

}
