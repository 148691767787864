
import { Component, OnInit,Inject, OnDestroy } from '@angular/core';
import { browserRefresh } from '../app.component';
import { Http_client_Service } from '../Service/http.service';
import {FormGroup, FormBuilder, Validators} from '@angular/forms';
import { Router } from '@angular/router';
import { _order_master ,_order_slave , User_info  } from './model';
import { Columns, Config, DefaultConfig } from 'ngx-easy-table';
import {MatDialog , MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import { NgxPermissionsService } from 'ngx-permissions';


@Component({ selector: 'app-test',
 templateUrl: './test.html',
 styleUrls: ['./test.css']
 })
export class TestComponent implements OnInit {


  browserRefresh : boolean = false;
  public configuration: Config;
  public columns: Columns[];
  public data = [{


 }];
 user_info = new User_info(0,'',0);

    constructor(public hs : Http_client_Service) {   this.configuration = { ...DefaultConfig };
    this.configuration.searchEnabled = true;
    this.columns = [
      { key: 'ORDER_DATE', title: 'Order Date' },
      { key: 'ID', title: 'Order No' },
      { key: 'ORDER_PERSON_ID', title: 'Order Person No' },
      { key: 'SHOP_NAME', title: 'Shop Name' },
      { key: 'SHOP_LOCATION', title: 'Location' },
    ];}

    ngOnInit() {

      this.browserRefresh = browserRefresh;

      console.log('br',this.browserRefresh)

      this.get_all_active_order_list();
    }

    get_all_active_order_list()
    {


      if(this.hs.click_status == -2)
          {
                  if(this.user_info.user_role != "Admin")
                  {
                    this.user_info.status_flag = 0;

                  }
                  else
                  {
                    this.user_info.status_flag = 1
                  }

          }
          else
          {
              this.user_info.status_flag = this.hs.status_flag;
          }


      this.hs.get_all_order_list(this.user_info)
        .subscribe((jsonData) => { this._get_bills(jsonData)
                },(err) => console.error(err),

                );
    }



_get_bills(json :any)
{



this.data = json;

console.log('Active orders',this.data)



}



eventEmitted(ev:any)
{
  console.log('ev',ev);
  if(ev.event != "onPagination")
  {


    if(ev.event == "onClick")
    {
      this.hs.order_id = ev.value.row.ID;
     // this.get_order_detail(ev.value.row.ID);
    //  this.get_order_person_detail(ev.value.row.ORDER_PERSON_ID)
    //  this.rej_reason = ev.value.row.REMARK;

    }


  }
}




}
