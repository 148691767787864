import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login';
import { Add_shopComponent } from './Shop/Add/add-shop';
import { DashComponent } from './dash/dash';
import {  TestComponent } from './test/test';
import { Setting_mainpage_Component} from './Settings/main_page/main_page';
import { neworder_Component } from './Order/New/new';
import { ViewActive_orderComponent } from './Order/View/view';
import { Editorder_Component } from './Order/Edit/edit';
import { View_shopComponent } from './Shop/view/view';
import { NgxPermissionsGuard } from 'ngx-permissions';
import { new_user_Component } from './Admin/new_user/new_user';

const routes: Routes = [
  { path: '', component: LoginComponent },
  { path: 'login', component: LoginComponent },
  { path: 'new-user', component: new_user_Component },
  { path: 'add-shop', component: Add_shopComponent ,  canActivate: [NgxPermissionsGuard], data: {   permissions: {  only: 'Admin'    }  } },
  { path: 'dash', component: DashComponent , canActivate: [NgxPermissionsGuard],  data: {   permissions: {   except: 'Guest'   } }},
  { path : 'settings', component: Setting_mainpage_Component, canActivate: [NgxPermissionsGuard], data: {   permissions: {  only: 'Admin'    }  }},
  { path : 'view_active_customers', component: View_shopComponent, canActivate: [NgxPermissionsGuard], data: {   permissions: {  only: 'Admin'    }  }},
  { path : 'order-new', component: neworder_Component , canActivate: [NgxPermissionsGuard], data: {   permissions: {  only: ['Admin','User','Shop']    }  }  },
  { path : 'order-edit', component: Editorder_Component , canActivate: [NgxPermissionsGuard], data: {   permissions: {  only: ['User','Shop']    }  } },
  { path : 'order-view-active', component: ViewActive_orderComponent , canActivate: [NgxPermissionsGuard], data: {   permissions: {   only: ['Admin','User','Shop']    } } },
  { path: '**', component: TestComponent }


];

@NgModule({
  imports: [RouterModule.forRoot(routes,{useHash:true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }


//{ path: '**', redirectTo: '/#/dash' }
