
import { Component, OnInit, OnDestroy } from '@angular/core';

import {Router} from '@angular/router';
import { Http_client_Service } from '../../Service/http.service';
import { FormGroup, FormControl,Validators } from '@angular/forms';
import {MatSnackBar} from '@angular/material/snack-bar';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';


@Component({
 selector: 'app-edit-order',
 templateUrl: './edit.html',
 styleUrls: ['./edit.css']
 })
export class Editorder_Component implements OnInit {

  updation_modal : any;
  mySource : any [] = [];
  pdtSource : any[] = [];
  SHOP_SELECTOR : any;
  PRODUCT_SELECTOR :any;
  P_SELECTOR : any;
  cat_list : any[]=[];
  bn : string = '';
  size_list :any[]=[];
  sz : string = '';
  color_list : any[]=[];
  clr : string = '';
  show_hide : boolean = true;
  pro_name : string = '';
  pro_id : number = 0;
  index : number = 0;
  sub_cat_list :any[]=[];
  sub_sub_cat_list : any[] = [];
  is_duplicate : number = 0;
  arr_ : number = 0;
  com_id : any;
  user_id : any;
  cat_one_name : string = '';
  cat_two_name : string = '';
  cat_three_name : string = '';
  post_form : any = {cat:'',sub_cat:'',sub_sub_cat:'',com_id:0}

  updationForm = new FormGroup({



    _CAT_U : new FormControl('', [Validators.required, Validators.minLength(2)]),
    _QTY_U : new FormControl('', [Validators.required]),
    _SUB_CAT_U : new FormControl('', [Validators.required, Validators.minLength(2)]),
    _SUB_SUB_CAT_U : new FormControl('', [Validators.required, Validators.minLength(2)]),
    _PRODUCT_U : new FormControl('', [Validators.required, Validators.minLength(2)]),
    _PDT_NAME_U : new FormControl(''),
    _REMARK_U : new FormControl(''),


  });




  order_detail_Form = new FormGroup({

    _ORDER_DATE : new FormControl(''),
    _SHOP_NAME : new FormControl({value: '', disabled: true}, [Validators.required, Validators.minLength(2)]),
    _SHOP_LOCATION : new FormControl({value: '', disabled: true}, [Validators.required, Validators.minLength(2)]),

    _PRODUCT_NAME : new FormControl('', [Validators.required, Validators.minLength(2)]),
    _PRODUCT_MODEL: new FormGroup({
      ID: new FormControl(''),
      PRODUCT_NAME: new FormControl(''),

        }),

    _REMARK: new FormControl(''),
    CAT_NAME  : new FormControl('', [Validators.required]),
    SUB_CAT_NAME : new FormControl('', [Validators.required, Validators.minLength(2)]),
    _QTY : new FormControl('', [Validators.required]),
    _SUB_SUB_CAT_NAME  : new FormControl('', [Validators.required]),


  });


  sub_sub_cat_Form = new FormGroup({
    SUB_SUB_CAT_NAME: new FormControl('', [Validators.required, Validators.minLength(1)]),
    CAT_NAME: new FormControl('', [Validators.required]),
    SUB_CAT_NAME: new FormControl('', [Validators.required]),
    COM_ID: new FormControl(''),
  })



    constructor(public rter: Router,public hs:Http_client_Service,private _snackBar: MatSnackBar) { }

    ngOnInit() {


      this.com_id = localStorage.getItem('com_id');
      this.user_id = localStorage.getItem('user_id');

      this.get_all_shop_details();
     // this.get_all_products();
       this.updation_modal =  document.getElementById("edit_updation_modal");
      while(this.hs.o_m.items.length){
        this.hs.o_m.items.pop();
      }
      this.get_order_details();
      this.get_company_dets(this.com_id)

      this.get_all_cats();
    }

    get_company_dets(id: number) {
      this.hs.get_company_dets(id)
        .subscribe((jsonData: any) => {
          this._get_res_company_details(jsonData)}, (err: any) => console.error(err));

    }
    _get_res_company_details(data: any) {
      this.cat_one_name = data.CAT_ONE_NAME;
      this.cat_two_name = data.CAT_TWO_NAME;
      this.cat_three_name = data.CAT_THREE_NAME;
    }

    openSnackBar(message: string, action: string) {
      this._snackBar.open(message, action, {
        duration: 2000,
        horizontalPosition: 'center',
        verticalPosition: 'bottom'
      });
    }


    get_order_details()
    {
      this.hs.get_order_detail_for_edit(this.hs.order_id)
      .subscribe((jsonData:any) => { this._load_dets(jsonData)
      },(err:any) => console.error(err),

      );


    }

    _load_dets(data:any)
    {
      console.log('data',data)

       this.load_master(data.master)
       this.load_details(data.detail)
    }

    load_master(master : any)
    {
      this.order_detail_Form.patchValue({ _ORDER_DATE :master.ORDER_DATE , _SHOP_NAME : master.SHOP_NAME , _SHOP_LOCATION : master.SHOP_LOCATION })
      this.hs.o_m.heads.ORDER_ID = master.ID;
      this.hs.o_m.heads.SHOP_ID = master.SHOP_ID;
    }

    load_details(detail:any)
    {
      this.hs.o_m.varibs.i = 0 ;

       if(detail.length > 0)
       {

         for(var i = 0; i< detail.length; i++)
         {
           this.push_item();

           this.hs.o_m.items[this.hs.o_m.varibs.i].SI_NO = detail[i].SI_NO
           this.hs.o_m.items[this.hs.o_m.varibs.i].PRODUCT_NAME =  detail[i].PRODUCT_NAME
           this.hs.o_m.items[this.hs.o_m.varibs.i].PRO_ID =   detail[i].PRO_ID
           this.hs.o_m.items[this.hs.o_m.varibs.i].CAT_NAME =  detail[i].CAT_NAME
           this.hs.o_m.items[this.hs.o_m.varibs.i].SUB_CAT_NAME =  detail[i].SUB_CAT_NAME
           this.hs.o_m.items[this.hs.o_m.varibs.i].QTY =  detail[i].QTY
           this.hs.o_m.items[this.hs.o_m.varibs.i].SUB_SUB_CAT_NAME =  detail[i].SUB_SUB_CAT_NAME
           this.hs.o_m.items[this.hs.o_m.varibs.i].REMARKS =  detail[i].REMARKS
           this.hs.o_m.varibs.i = this.hs.o_m.varibs.i + 1;

         }



       }


    }








    onSubmit(customerData : any) {



      console.log('Your order has been submitted', customerData);

      this.rter.navigate(['/dash']);



    }








    get_all_products()
    {

      this.hs.get_all_products()
      .subscribe((jsonData:any) => { this.pdtSource = jsonData
              },(err:any) => console.error(err),

              );

    }







    change_product_name(pdt_name:any)
    {
      console.log(pdt_name)
      let bi : any;
      bi = this.pdtSource.filter(xi=> xi.PRODUCT_NAME  == pdt_name);

        if(bi.length == 1)
        {

            this.pro_name = pdt_name;
            this.pro_id = bi[0].ID



        }
    }




    modal_close_edit_details()
    {
    this.updation_modal.style.display="none"
    }




  push_item()
  {

    this.hs.o_m.items.push({SI_NO:1,PRODUCT_NAME:'',PRO_ID:0,QTY:1,UNIT:'',CAT_NAME:'',SUB_CAT_NAME:'',SUB_SUB_CAT_NAME:'',BAR_CODE:'',ORDER_NO:0,ORDER_DATE:new Date(),MODE_:'',REMARKS:''})
  }



    get_all_shop_details()
    {

      this.com_id = localStorage.getItem('com_id');
      this.hs.get_all_shop_details(this.com_id)
      .subscribe((jsonData:any) => { this._get_all_shop_details(jsonData)
              },(err:any) => console.error(err),

              );
    }

    _get_all_shop_details(shops:any)
    {
      this.mySource = shops;
    }


    change_shop_name(i_d:any)
    {
      // console.log(i_d)
      // let bi : any;
      // bi = this.mySource.filter(xi=> xi.ID  == i_d);

      //   if(bi.length == 1)
      //   {

      //     this.hs.o_m.heads.SHOP_ID    =   i_d;
      //     this.hs.o_m.heads.SHOP_NAME = bi[0].SHOP_NAME;

      //     this.order_detail_Form.patchValue({ _SHOP_NAME : this.hs.o_m.heads.SHOP_NAME , _SHOP_LOCATION :  bi[0].SHOP_LOCATION })


      //   }
    }

    add_detail()
    {




      if(this.hs.o_m.varibs.i != -1)
      {

      //  this.is_duplicate =  this.check_for_dupliction(this.order_detail_Form.controls._BRAND.value,this.order_detail_Form.controls._COLOR.value,
      //   this.order_detail_Form.controls._SIZE.value,
      // this.pro_name)



         if(this.is_duplicate > 0)
         {

           this.openSnackBar('Duplicate Entry','')
         }
         else
         {

          this.normal_add()
         }




        }

     else
     {
         this.normal_add();

     }




    }


    normal_add()
    {
      this.push_item();


      this.hs.o_m.items[this.hs.o_m.varibs.i].SI_NO =  this.hs.o_m.varibs.i + 1;
      this.hs.o_m.items[this.hs.o_m.varibs.i].PRODUCT_NAME = this.pro_name;
      this.hs.o_m.items[this.hs.o_m.varibs.i].PRO_ID = this.pro_id;
      this.hs.o_m.items[this.hs.o_m.varibs.i].CAT_NAME = this.order_detail_Form.controls.CAT_NAME.value;
      this.hs.o_m.items[this.hs.o_m.varibs.i].SUB_CAT_NAME = this.order_detail_Form.controls.SUB_CAT_NAME.value;
      this.hs.o_m.items[this.hs.o_m.varibs.i].QTY = this.order_detail_Form.controls._QTY.value;
      this.hs.o_m.items[this.hs.o_m.varibs.i].SUB_SUB_CAT_NAME = this.order_detail_Form.controls._SUB_SUB_CAT_NAME.value;
      this.hs.o_m.items[this.hs.o_m.varibs.i].REMARKS = this.order_detail_Form.controls._REMARK.value;

      this.hs.o_m.varibs.i = this.hs.o_m.varibs.i + 1;


      this.order_detail_Form.controls.SUB_CAT_NAME.reset();
      this.order_detail_Form.controls._SUB_SUB_CAT_NAME.reset();
      this.order_detail_Form.controls._QTY.reset();


      this.order_detail_Form.controls.CAT_NAME.reset();

      this.order_detail_Form.controls._REMARK.reset();
      this.order_detail_Form.controls._PRODUCT_NAME.reset();
    }


    submit_fresh_order()
    {

      this.hs.o_m.heads.STATUS_FLAG = 2;

      this.forward_to_post();
    }

    put_to_hold()
    {
      this.hs.o_m.heads.STATUS_FLAG = 0;

      this.forward_to_post()
    }

    forward_to_post()
    {

      this.hs.o_m.heads.ORDER_PERSON_ID = this.hs.user_id;
      this.hs.o_m.heads.SHOP_NAME = this.order_detail_Form.controls._SHOP_NAME.value;
      this.hs.o_m.heads.ORDER_DATE = this.order_detail_Form.controls._ORDER_DATE.value;
      this.hs.o_m.heads.SHOP_LOCATION = this.order_detail_Form.controls._SHOP_LOCATION.value;


      this.hs.update_order(this.hs.o_m)
      .subscribe((jsonData:any) => { this._get_res_post_order(jsonData)
              },(err:any) => console.error(err),

              );

    }


    _get_res_post_order(js_res:any)
    {

      this.openSnackBar(js_res.msg,'')

      while(this.hs.o_m.items.length){
        this.hs.o_m.items.pop();
      }


      this.rter.navigate(['/dash'])

    }


    Show_hide()
    {
      this.show_hide = !this.show_hide;
    }

    editItem(k: number)
    {


      this.index = k
      this.updation_modal.style.display = "block";
      this.updationForm.controls._CAT_U.setValue(this.hs.o_m.items[this.index].CAT_NAME)


         this.oncatSelected_updation(this.hs.o_m.items[this.index].CAT_NAME)


       this.updationForm.controls._SUB_CAT_U.setValue(this.hs.o_m.items[this.index].SUB_CAT_NAME)
       this.sub_sub_cat_list[0] = this.hs.o_m.items[this.index].SUB_SUB_CAT_NAME;
       this.updationForm.controls._SUB_SUB_CAT_U.setValue(this.hs.o_m.items[this.index].SUB_SUB_CAT_NAME)
       this.updationForm.controls._PRODUCT_U.setValue(this.hs.o_m.items[this.index].PRODUCT_NAME)

       this.updationForm.controls._QTY_U.setValue(this.hs.o_m.items[this.index].QTY)
       this.updationForm.controls._REMARK_U.setValue(this.hs.o_m.items[this.index].REMARKS)


    }

    update_edited_details()
    {


    //  this.is_duplicate =  this.check_for_dupliction_edit( this.updationForm.controls._BRAND_U.value,this.updationForm.controls._COLOR_U.value,
    //   this.updationForm.controls._SIZE_U.value,
    //     this.hs.o_m.items[this.index].PRODUCT_NAME )



        console.log('dupliate',this.is_duplicate)

        if( this.is_duplicate > 0)
        {

           this.openSnackBar('Duplicate Entry','')
        }
        else
        {

             this.hs.o_m.items[this.index].CAT_NAME = this.updationForm.controls._CAT_U.value;
             this.hs.o_m.items[this.index].SUB_CAT_NAME = this.updationForm.controls._SUB_CAT_U.value;
             this.hs.o_m.items[this.index].SUB_SUB_CAT_NAME = this.updationForm.controls._SUB_SUB_CAT_U.value;
            this.hs.o_m.items[this.index].QTY = this.updationForm.controls._QTY_U.value;
            this.hs.o_m.items[this.index].REMARKS = this.updationForm.controls._REMARK_U.value;
            this.hs.o_m.items[this.index].PRODUCT_NAME = this.updationForm.controls._PDT_NAME_U.value;
        }




    }

    // check_for_dupliction_edit(brand:any,col:any,size:any,p_n:any) : any
    // {


    //   var x = this.hs.o_m.items.filter(xy => xy.BRAND == brand && xy.COLOR == col && xy.SIZE == size && xy.PRODUCT_NAME == p_n)

    //    console.log('x',x);
    //    console.log('i',this.hs.o_m.varibs.i);

    //    if(x.length == 1 && x[0].SI_NO === this.hs.o_m.varibs.i + 1)
    //     {

    //        console.log('zero');
    //        return 0
    //     }
    //     else
    //     {
    //       console.log('one');
    //       return 1
    //     }
    // }



    //  check_for_dupliction(brand:any,col:any,size:any,p_n:any) : any
    // {


    //   var x = this.hs.o_m.items.filter(xy => xy.BRAND == brand && xy.COLOR == col && xy.SIZE == size && xy.PRODUCT_NAME == p_n)

    //    console.log('length',x.length);



    //  return x.length;


    // }



    RemoveItem(k:any)
    {

      if(this.hs.o_m.items.length != 1)
      {
        for(this.arr_ = k ; this.arr_ <= this.hs.o_m.varibs.i-2 ; this.arr_ ++)
        {
         this.hs.o_m.items[this.arr_].PRODUCT_NAME = this.hs.o_m.items[this.arr_+1].PRODUCT_NAME;
         this.hs.o_m.items[this.arr_].PRO_ID = this.hs.o_m.items[this.arr_+1].PRO_ID;
         this.hs.o_m.items[this.arr_].CAT_NAME = this.hs.o_m.items[this.arr_+1].CAT_NAME;
         this.hs.o_m.items[this.arr_].SUB_SUB_CAT_NAME = this.hs.o_m.items[this.arr_+1].SUB_SUB_CAT_NAME;
         this.hs.o_m.items[this.arr_].SUB_CAT_NAME = this.hs.o_m.items[this.arr_+1].SUB_CAT_NAME;
         this.hs.o_m.items[this.arr_].QTY = this.hs.o_m.items[this.arr_+1].QTY;
          console.log('cats#####................');

        }

      }


      this.hs.o_m.items.pop();

      this.hs.o_m.varibs.i = this.hs.o_m.varibs.i-1;



    }





    get_all_cats()
    {
      this.com_id  =  localStorage.getItem('com_id');
      this.hs.get_all_cats(this.com_id)
      .subscribe((jsonData:any) => { this._get_res_cat_list(jsonData)
              },(err:any) => console.error(err),

              );
    }

    _get_res_cat_list(cats : any)
    {
      console.log('cats',cats);
      while(this.cat_list.length){
        this.cat_list.pop();
      }

      for(var j = 0;j<cats.length;j++ )
       {
        this.cat_list[j] = cats[j].CATEGORY_NAME
       }


    }


    oncatSelected_updation(cat:any)
    {
      this.com_id  =  localStorage.getItem('com_id');
      this.post_form.cat = cat;
         this.sub_sub_cat_Form.patchValue({ CAT_NAME : cat, COM_ID : this.com_id});
         this.hs.get_all_sub_cat_on_change_cat(this.sub_sub_cat_Form.value)
         .subscribe((jsonData: any) => {
           this._get_res_sub_cats_on_cat_change_on_updation(jsonData)
         }, (err: any) => console.error(err),
         );
    }
    _get_res_sub_cats_on_cat_change_on_updation(js:any)
    {
          while (this.sub_cat_list.length) {
            this.sub_cat_list.pop();
          }
          for (var j = 0; j < js.length; j++) {
            this.sub_cat_list[j] = js[j].SUB_CATEGORY_NAME
          }
    }

    oncatSelected(ev:any)
    {
      console.log('ev',ev);
      this.com_id  =  localStorage.getItem('com_id');
      this.post_form.cat = ev;
         this.sub_sub_cat_Form.patchValue({ CAT_NAME : ev, COM_ID : this.com_id});
         this.hs.get_all_sub_cat_on_change_cat(this.sub_sub_cat_Form.value)
         .subscribe((jsonData: any) => {
           this._get_res_sub_cats_on_cat_change(jsonData)
         }, (err: any) => console.error(err),
         );
    }

    _get_res_sub_cats_on_cat_change(sub_cats:any)
    {

      console.log('data',sub_cats);
      while (this.sub_cat_list.length) {
        this.sub_cat_list.pop();
      }
      for (var j = 0; j < sub_cats.length; j++) {
        this.sub_cat_list[j] = sub_cats[j].SUB_CATEGORY_NAME
      }

    }



    change_product_name_on_edit(ev:any)
    {
      console.log('ev',ev);
      this.pro_name = ev.PRODUCT_NAME;
      this.pro_id = ev.ID;
      this.updationForm.patchValue({_PRODUCT_U :  this.pro_name})
    }

    customCallback(ev:any)
    {
      this.openSnackBar('Wrong Selection','')
    }



    onsub_sub_catSelected(ev:any)
    {
      console.log('ev',ev);
      this.post_form.sub_sub_cat = ev;
      this.post_form.com_id =  localStorage.getItem('com_id');
      this.order_detail_Form.patchValue({_SUB_SUB_CAT_NAME : ev});
      this.hs.get_all_products_after_filter(this.post_form)
       .subscribe((jsonData:any) => { this.pdtSource = jsonData , console.log('dadad',this.pdtSource);
    },(err:any) => console.error(err),
    );

   // this.divView.nativeElement.value = '';
    }

    onsubcatSelected(ev:any)
    {
      console.log('ev',ev);
      this.post_form.sub_cat = ev;
        this.com_id  =  localStorage.getItem('com_id');


         this.sub_sub_cat_Form.patchValue({ SUB_CAT_NAME : ev, COM_ID : this.com_id});

         this.hs.get_all_sub_sub_cat_on_change_sub_cat(this.sub_sub_cat_Form.value)
         .subscribe((jsonData: any) => {
           this._get_res_sub_sub_cats_on_cat_change(jsonData)
         }, (err: any) => console.error(err),
         );
    }

    _get_res_sub_sub_cats_on_cat_change(sub_sub_cats:any)
    {
      console.log('evsub_sub_cats',sub_sub_cats);

      while (this.sub_sub_cat_list.length) {
        this.sub_sub_cat_list.pop();
      }
      for (var j = 0; j < sub_sub_cats.length; j++) {
        this.sub_sub_cat_list[j] = sub_sub_cats[j].SUB_SUB_CAT_NAME
      }

    }

    onsub_sub_catSelected_on_edit(ev:any)
    {
      this.post_form.sub_sub_cat = ev;
      this.post_form.com_id =  localStorage.getItem('com_id');
      this.hs.get_all_products_after_filter(this.post_form)
       .subscribe((jsonData:any) => { this.pdtSource = jsonData , console.log('dadad',this.pdtSource);
    },(err:any) => console.error(err),
    );
    }



}




