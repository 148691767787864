

import { Component, OnDestroy } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { Http_client_Service } from './Service/http.service';
import { Subscription } from 'rxjs';
import { NgxPermissionsService } from 'ngx-permissions';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
export let browserRefresh = false;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnDestroy {
  title = 'ofos';
  user_role: any = '';
  user_id : any ;

  subscription: Subscription;
  constructor(private router: Router,private hs: Http_client_Service,private ps: NgxPermissionsService) {
    this.subscription = router.events.subscribe((event) => {
        if (event instanceof NavigationStart) {
          browserRefresh = !router.navigated;
        }
    });

 }

 ngOnInit() {




    this.user_role  =   localStorage.getItem('user_role');
    this.user_id  =  localStorage.getItem('user_id');
    this.hs.com_id = localStorage.getItem('com_id');
    this.hs.user_id = this.user_id;
    this.hs.user_role = this.user_role;
    this.ps.loadPermissions([this.user_role]);
   }


 ngOnDestroy() {
  this.subscription.unsubscribe();
}

}
