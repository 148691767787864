<nav aria-label="breadcrumb">
  <ol class="breadcrumb">
    <li class="breadcrumb-item"><a href="/#/dash">Home</a></li>

    <li class="breadcrumb-item active" aria-current="page">settings</li>




  </ol>



</nav>



<div class="container">



<table class="table table-bordered table-responsive-sm">
  <thead>
    <tr style="background-color: skyblue;">
      <th scope="col">Company</th>
      <th scope="col">Product</th>
      <th scope="col">User</th>
      <th scope="col">Shops</th>
    </tr>
  </thead>
  <tbody>

    <tr>

      <td *ngIf="this.com_id === 0">  <button type="button" style="width: 100%;font-weight: 500;" (click)="add_company()" class="btn btn-outline-primary">Add Company</button>      </td>
      <td *ngIf="this.com_id != 0" style="text-align: center;
      font-family: sans-serif;
      font-weight: 800;
      color: mediumslateblue;">   {{com_name}}    </td>

      <td> <button type="button" style="width: 100%;font-weight: 500;" (click)="add_product()" class="btn btn-outline-primary">Add Product</button>
        </td>
      <td><button type="button"  style="width: 100%;font-weight: 500;" (click)="add_user()" class="btn btn-outline-primary">Add New User</button></td>
      <td><button type="button"  style="width: 100%;font-weight: 500;" (click)="add_shop()" class="btn btn-outline-primary">Add shop</button></td>
    </tr>

    <tr>
      <td> <button type="button"  style="width: 100%;font-weight: 500;" (click)="manage_company()" class="btn btn-outline-primary"> Update Company  </button>  </td>
      <td> <button type="button"  style="width: 100%;font-weight: 500;" (click)="delete_product()" class="btn btn-outline-primary"> Delete Product  </button>  </td>
      <td> <button type="button"  style="width: 100%;font-weight: 500;" (click)="update_user()" class="btn btn-outline-primary"> Manage User </button> </td>
      <td> <button type="button"  style="width: 100%;font-weight: 500;" (click)="update_shop()" class="btn btn-outline-primary"> Update shop </button></td>
    </tr>
  </tbody>
</table>




<table class="table table-bordered">
  <thead>
    <tr style="background-color: skyblue;font-weight: 500;">

      <th scope="col">Category</th>
      <th scope="col">Sub Category</th>
      <th scope="col">Sub Sub Category</th>
    </tr>
  </thead>
  <tbody>
    <tr>

      <td>  <button type="button"  style="width: 100%;font-weight: 500;" (click)="open_modal_add_category()" class="btn btn-outline-secondary">Add {{cat_one_name}} </button>    </td>
      <td>  <button type="button"  style="width: 100%;font-weight: 500;" (click)="open_modal_add_sub_cat()" class="btn btn-outline-secondary">Add New {{cat_two_name}}    </button>   </td>
      <td>  <button type="button"  style="width: 100%;font-weight: 500;" (click)="open_modal_add_sub_sub_cat()" class="btn btn-outline-secondary">Add New {{cat_three_name}} </button>   </td>


    </tr>

    <tr>

      <td><button type="button"  style="width: 100%;font-weight: 500;"  (click)="open_modal_delete_cat()"class="btn btn-outline-danger">Delete {{cat_one_name}} </button></td>
      <td> <button type="button"  style="width: 100%;font-weight: 500;" (click)="open_modal_delete_sub_cat()" class="btn btn-outline-danger">Delete {{cat_two_name}} </button></td>
      <td> <button type="button"  style="width: 100%;font-weight: 500;" (click)="open_modal_delete_sub_sub_cat()" class="btn btn-outline-danger">Delete {{cat_three_name}} </button></td>
    </tr>
  </tbody>
</table>

</div>


 <!--       add_shop_modal  -->

 <form [formGroup]="shopForm">
  <div id="add_shop_modal" class="modal" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Add Shop</h5>
          <button type="button" (click)="modal_close_add_shop()" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-12 col-md-12 col-lg-12 col-xl-12">
                    <label>Shop Name</label>
                    <input type="text" formControlName="SHOP_NAME" class="form-control" />
                </div>

                <div class="col-12 col-md-12 col-lg-12 col-xl-12">
                  <label>Location </label>
                  <input type="text" formControlName="SHOP_LOCATION" class="form-control" />
              </div>

              <div class="col-12 col-md-12 col-lg-12 col-xl-12">
                <label>GSTIN </label>
                <input type="text" formControlName="SHOP_GSTIN" class="form-control" />
            </div>

            <div class="col-12 col-md-12 col-lg-12 col-xl-12">
              <label>Phone </label>
              <input type="phone" formControlName="SHOP_PHONE" class="form-control" />
          </div>

          <div class="col-12 col-md-12 col-lg-12 col-xl-12">
            <label>Email </label>
            <input type="email" formControlName="SHOP_EMAIL" class="form-control" />
        </div>
         </div>
       </div>
      </div>
          <div class="modal-footer">

                    <button [disabled]="!shopForm.valid" type="button"(click)="post_add_shop()" class="btn btn-primary btn-lg btn-block">Add</button>



          </div>
        </div>
      </div>
    </div>

    </form>




 <!--     add_shop_modal end       -->








  <!--     update shop modal start       -->


  <form [formGroup]="shop_updation_Form">
    <div id="update_shop_modal" class="modal" tabindex="-1" role="dialog">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Add Shop</h5>
            <button type="button" (click)="modal_close_update_shop()" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="container">
              <div class="row justify-content-center">
                <div class="col-12 col-md-12 col-lg-12 col-xl-12">
                      <label>Shop Name</label>
                      <input  (valueChanged)="search_shop($event)"
                         auto-complete formControlName="ID_mock" list-formatter=" SHOP_NAME -- SHOP_LOCATION"
                          loading-text = "loading"
                          display-property-name="SHOP_NAME" class="form-control" [source]="mySource"/>

                  </div>

                  <div class="col-12 col-md-12 col-lg-12 col-xl-12">
                    <label>Location </label>
                    <input type="text" formControlName="SHOP_LOCATION" class="form-control" />
                </div>

                <div class="col-12 col-md-12 col-lg-12 col-xl-12">
                  <label>GSTIN </label>
                  <input type="text" formControlName="SHOP_GSTIN" class="form-control" />
              </div>

              <div class="col-12 col-md-12 col-lg-12 col-xl-12">
                <label>Phone </label>
                <input type="phone" formControlName="SHOP_PHONE" class="form-control" />
            </div>

            <div class="col-12 col-md-12 col-lg-12 col-xl-12">
              <label>Email </label>
              <input type="email" formControlName="SHOP_EMAIL" class="form-control" />
          </div>
           </div>
         </div>
        </div>
            <div class="modal-footer">

                      <button [disabled]="!shop_updation_Form.valid" type="button"(click)="post_update_shop()" class="btn btn-primary btn-lg btn-block">Update</button>



            </div>
          </div>
        </div>
      </div>

      </form>




<!--     update shop modal end       -->







<!--              Add company modal             -->


<form [formGroup]="company_Form">
  <div id="company_add_modal" class="modal" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Company Details</h5>
          <button type="button" (click)="modal_close_company()" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">


          <div class="container">
            <div class="row justify-content-center">
              <div class="col-12 col-md-12 col-lg-12 col-xl-12">
                    <label>Company Name</label>
                    <input type="text" formControlName="COMPANY_NAME" class="form-control" />
                </div>

                <div class="col-12 col-md-12 col-lg-12 col-xl-12">
                  <label>Address </label>
                  <input type="text" formControlName="COMPANY_ADDRESS" class="form-control" />
              </div>

              <div class="col-12 col-md-12 col-lg-12 col-xl-12">
                <label>City </label>
                <input type="text" formControlName="COMPANY_CITY" class="form-control" />
            </div>

            <div class="col-12 col-md-12 col-lg-12 col-xl-12">
              <label>Phone </label>
              <input type="text" formControlName="COMPANY_PH" class="form-control" />
          </div>
         </div>

           </div>

        </div>
        <div class="modal-footer">

          <div class="container">
            <div class="row justify-content-center">
              <div class="col-12 col-md-12 col-lg-12 col-xl-12">
                    <label>Main Category name </label>
                    <input type="text" formControlName="CAT_ONE_NAME" class="form-control" />
                </div>
                <div class="col-12 col-md-12 col-lg-12 col-xl-12">
                  <label>Sub Category name </label>
                  <input type="text" formControlName="CAT_TWO_NAME" class="form-control" />
              </div>
              <div class="col-12 col-md-12 col-lg-12 col-xl-12">
                <label>Sub Sub Category name </label>
                <input type="text" formControlName="CAT_THREE_NAME" class="form-control" />
            </div>
              </div>
            </div>


        </div>



        <div class="modal-footer">
          <button type="button"  (click)="modal_close_company()"  class="btn btn-secondary" data-dismiss="modal">Close</button>
          <button type="button"(click)="Save_company()" class="btn btn-primary">Save </button>
        </div>
      </div>
    </div>
  </div>
  </form>




<!--              Add company modal  end           -->



<!--              manage company modal             -->



<form [formGroup]="company_manage_Form">
  <div id="manage_company_modal" class="modal" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Company Details</h5>
          <button type="button" (click)="modal_close_manage_company()" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">


          <div class="container">
            <div class="row justify-content-center">
              <div class="col-12 col-md-12 col-lg-12 col-xl-12">
                    <label>Company Name</label>
                    <input type="text" formControlName="COMPANY_NAME" class="form-control" />
                </div>

                <div class="col-12 col-md-12 col-lg-12 col-xl-12">
                  <label>Address </label>
                  <input type="text" formControlName="COMPANY_ADDRESS" class="form-control" />
              </div>

              <div class="col-12 col-md-12 col-lg-12 col-xl-12">
                <label>City </label>
                <input type="text" formControlName="COMPANY_CITY" class="form-control" />
            </div>

            <div class="col-12 col-md-12 col-lg-12 col-xl-12">
              <label>Phone </label>
              <input type="text" formControlName="COMPANY_PH" class="form-control" />
          </div>
         </div>

           </div>

        </div>
        <div class="modal-footer">

          <div class="container">
            <div class="row justify-content-center">
              <div class="col-12 col-md-12 col-lg-12 col-xl-12">
                    <label>Selection One name </label>
                    <input type="text" formControlName="CAT_ONE_NAME" class="form-control" />
                </div>
                <div class="col-12 col-md-12 col-lg-12 col-xl-12">
                  <label>Selection Two name </label>
                  <input type="text" formControlName="CAT_TWO_NAME" class="form-control" />
              </div>
              <div class="col-12 col-md-12 col-lg-12 col-xl-12">
                <label>Selection Three name </label>
                <input type="text" formControlName="CAT_THREE_NAME" class="form-control" />
            </div>
              </div>
            </div>
        </div>
        <div class="modal-footer">
          <button type="button"  (click)="modal_close_manage_company()"  class="btn btn-secondary" data-dismiss="modal">Close</button>
          <button type="button"(click)="Update_company()" class="btn btn-primary"> Update </button>
        </div>
      </div>
    </div>
  </div>
  </form>


<!--              manage company modal  end            -->




<!--              Add Product modal           -->

<form [formGroup]="productForm">
  <div id="add_product_modal" class="modal" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Product Description</h5>
          <button type="button" (click)="modal_close_add_product()" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">





          <div class="col-sm-12 pb-3">
            <label> Select {{cat_one_name}} </label>
             <select   #miip  (change)='oncatSelected(miip.value)'  formControlName="CAT_NAME"  class="form-control custom-select">
              <option class="text-white bg-warning">
                Pick any One
              </option>
                <option *ngFor="let cat of cat_list" [value]="cat">{{cat}}</option>
            </select>
          </div>




         <div class="col-sm-12 pb-3">
                <label for="exampleSt"> Select {{this.cat_two_name}} </label>
                <select   #mis  (change)='onsubcatSelected(mis.value)'  formControlName="SUB_CAT_NAME" class="form-control custom-select">
                  <option class="text-white bg-warning">
                    Pick any One
                  </option>
                    <option *ngFor="let typ of sub_cat_list" [value]="typ">{{typ}}</option>
                  </select>
              </div>



          <div class="col-sm-12 pb-3">
            <label for="exampleSt"> Select {{this.cat_three_name}} </label> <select  formControlName="SUB_SUB_CAT_NAME" class="form-control custom-select">
              <option class="text-white bg-warning">
                Pick any One
              </option>
                <option *ngFor="let type of sub_sub_cat_list" [value]="type">{{type}}</option>
              </select>
          </div>





          <label> Product Name</label>
          <input formControlName="PRODUCT_NAME" type="text" class="form-control" >
        </div>
        <div class="modal-footer">
          <button type="button"  (click)="modal_close_add_product()"  class="btn btn-secondary" data-dismiss="modal">Close</button>
          <button [disabled]="!productForm.valid" type="button" (click)="save_new_product()" class="btn btn-primary">Save </button>
        </div>
      </div>
    </div>
  </div>
  </form>


  <!--              Add Product modal      end     -->



  <!--              Delete Product modal             -->

<form [formGroup]="productForm">
  <div id="delete_product_modal" class="modal" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Delete Product</h5>
          <button type="button" (click)="close_modal_delete_product()" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <label for="delete_brand">Select Product</label>
          <select  formControlName="PRODUCT_NAME" id="delete_product" class="form-control">
            <option *ngFor="let type of product_list" [value]="type">{{type}}</option>
          </select>
        </div>
        <div class="modal-footer">
          <button type="button"  (click)="close_modal_delete_product()"  class="btn btn-secondary" data-dismiss="modal">Close</button>
          <button type="button" (click)="delete_product_from_list()" class="btn btn-danger">Delete</button>
        </div>
      </div>
    </div>
  </div>
  </form>

  <!--              Delete Product modal    end         -->













































<!--              Manage user modal             -->






<form [formGroup]="userForm">
  <div id="manage_user_modal" class="modal" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Manage user</h5>
          <button type="button" (click)="modal_close_manage_user()" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">

          <label for="input_product_1">User Search</label>

          <input   (valueChanged)="myuser_search_back($event)" (customSelected)="customCallback($event)"
           auto-complete formControlName="USER_NAME" list-formatter=" USER_NAME -- USER_ROLE"
          loading-text = "loading"
          display-property-name="USER_NAME" class="form-control" [source]="array_of_users"/>

          <label>Password</label>
          <input formControlName="PASSWORD" type="password" class="form-control">

          <label>Email</label>
          <input formControlName="EMAIL" type="email" class="form-control">

          <label>Select Role</label>
          <select  formControlName="USER_ROLE" id="user_role" class="form-control">
            <option  selected>Admin</option>
            <option *ngFor="let type of options" [value]="type">{{type}}</option>
          </select>



        </div>
        <div class="modal-footer">
          <button type="button"  (click)="modal_close_manage_user()"  class="btn btn-secondary" data-dismiss="modal">Close</button>
          <button type="button" (click)="update_user_details()"  class="btn btn-primary">Save </button>
        </div>
      </div>
    </div>
  </div>
  </form>


<!--              Manage user modal end     (keyup)="change_user_name(buser.value)" (blur)="change_user_name(buser.value)"        -->




<!--              Add category modal             -->

<form [formGroup]="categoryForm">
<div id="add_cat_modal" class="modal" tabindex="-1" role="dialog">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{cat_one_name}} Name</h5>
        <button type="button" (click)="modal_close_add_cat()" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">

        <input formControlName="CATEGORY_NAME" type="text" class="form-control" id="input_text_brandname">
      </div>
      <div class="modal-footer">
        <button type="button"  (click)="modal_close_add_cat()"  class="btn btn-secondary" data-dismiss="modal">Close</button>
        <button  [disabled]="!categoryForm.valid" type="button" (click)="Save_cat_name()"  class="btn btn-primary">Save </button>
      </div>
    </div>
  </div>
</div>
</form>

<!--              Add cat modal   end           -->


<!--              Delete cat modal             -->

<form [formGroup]="categoryForm">
  <div id="delete_cat_modal" class="modal" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Delete {{cat_one_name}} </h5>
          <button type="button" (click)="close_modal_delete_cat()" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">



          <label for="delete_brand">Select  {{cat_one_name}}  </label>
          <select  formControlName="CATEGORY_NAME" id="delete_brand" class="form-control">
            <option *ngFor="let type of cat_list" [value]="type">{{type}}</option>
          </select>


        </div>
        <div class="modal-footer">
          <button type="button"  (click)="close_modal_delete_cat()"  class="btn btn-secondary" data-dismiss="modal">Close</button>
          <button type="button" (click)="delete_cat()" class="btn btn-danger">Delete</button>
        </div>
      </div>
    </div>
  </div>
  </form>


  <!--              Delete cat modal  end           -->






<!--              Add sub cat modal           -->

<form [formGroup]="sub_cat_Form">
<div id="add_sub_cat_modal" class="modal" tabindex="-1" role="dialog">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title"> Add  {{cat_two_name}} </h5>
        <button type="button" (click)="modal_close_sub_cat()" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">



        <label>Select {{cat_one_name}}</label>
        <select   #mySelect    formControlName="CAT_NAME"  class="form-control">

          <option *ngFor="let cat of cat_list" [value]="cat">{{cat}}</option>
        </select>

        <br>


        <input placeholder="{{cat_two_name}}" formControlName="SUB_CATEGORY_NAME"  type="text" class="form-control" id="input_text_size">
      </div>
      <div class="modal-footer">
        <button type="button"  (click)="modal_close_sub_cat()"  class="btn btn-secondary" data-dismiss="modal">Close</button>
        <button type="button"(click)="Save_sub_cat()" class="btn btn-primary">Save </button>
      </div>
    </div>
  </div>
</div>
</form>


<!--              Add sub cat modal   end           -->


<!--              Delete sub cat modal             -->

<form [formGroup]="sub_cat_del_Form">

  <div id="delete_sub_cat_modal_" class="modal" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Delete {{cat_two_name}}</h5>
          <button type="button"   (click)="close_modal_delete_sub_cat()"  class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <label for="delete_size">Select {{cat_two_name}}</label>
          <select  formControlName="SUB_CAT_NAME"  class="form-control">
            <option *ngFor="let cat of sub_cat_list_inde" [value]="cat">{{cat}}</option>
          </select>
        </div>
        <div class="modal-footer">
          <button type="button"   (click)="close_modal_delete_sub_cat()"  class="btn btn-secondary" data-dismiss="modal">Close</button>
          <button type="button" (click)="delete_sub_cat()" class="btn btn-danger">Delete</button>
        </div>
      </div>
    </div>
  </div>
  </form>

  <!--              Delete sub cat modal  end           -->








<!--              Add sub sub cat modal              -->

<form [formGroup]="sub_sub_cat_Form">

<div id="add_sub_sub_cat_modal" class="modal" tabindex="-1" role="dialog">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">New {{cat_three_name}}</h5>
        <button type="button" (click)="modal_close_add_sub_sub_cat()" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">

        <label>Select {{cat_one_name}}</label>
        <select #mii  (change)='onOptionsSelected(mii.value)' formControlName="CAT_NAME"  class="form-control">

          <option *ngFor="let cat of cat_list" [value]="cat">{{cat}}</option>
        </select>

        <label>Select {{cat_two_name}} </label>
        <select  formControlName="SUB_CAT_NAME"  class="form-control">

          <option *ngFor="let sub_cat of sub_cat_list" [value]="sub_cat">{{sub_cat}}</option>
        </select>

        <br>

        <input formControlName="SUB_SUB_CAT_NAME"  placeholder="{{cat_three_name}}" type="text" class="form-control" id="input_text_color">
      </div>
      <div class="modal-footer">
        <button type="button"  (click)="modal_close_add_sub_sub_cat()"  class="btn btn-secondary" data-dismiss="modal">Close</button>
        <button type="button" (click)="save_sub_sub_cat()" class="btn btn-primary">Save </button>
      </div>
    </div>
  </div>
</div>



</form>

<!--              Add sub sub modal   end           -->










<!--              Delete  sub sub  cat modal             -->

<form [formGroup]="sub_sub_cat_del_Form">


<div id="delete_sub_sub_cat_modal" class="modal" tabindex="-1" role="dialog">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Delete {{cat_three_name}} </h5>
        <button type="button" (click)="close_modal_delete_sub_sub_cat()" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">



        <label>{{cat_three_name}}</label>
        <select  formControlName="SUB_SUB_CAT_NAME"  class="form-control">
          <option *ngFor="let sub_sub_cat of sub_sub_cat_list_inde" [value]="sub_sub_cat">{{sub_sub_cat}}</option>
        </select>
      </div>
      <div class="modal-footer">
        <button type="button"  (click)="close_modal_delete_sub_sub_cat()"  class="btn btn-secondary" data-dismiss="modal">Close</button>
        <button type="button" (click)="delete_sub_sub_cat()" class="btn btn-danger">Delete</button>
      </div>
    </div>
  </div>
</div>
</form>

<!--              Delete sub sub modal  end          -->



















<!--              Add user modal           -->


<form [formGroup]="userForm">
<div id="add_user_modal" class="modal" tabindex="-1" role="dialog">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Add user</h5>
        <button type="button" (click)="modal_close_add_user()" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <label for="username">Name</label>
        <input formControlName="USER_NAME" type="text" class="form-control" > <br>
        <label for="username">User Email</label>
        <input formControlName="EMAIL" type="text" class="form-control" > <br>
        <label for="password">Password</label>
        <input formControlName="PASSWORD" type="password" class="form-control" > <br>
        <label for="inputState">User Role</label>
        <select  formControlName="USER_ROLE" id="inputState" class="form-control">
          <option  selected>Admin</option>
          <option *ngFor="let type of options" [value]="type">{{type}}</option>
        </select>




      </div>
      <div class="modal-footer">
        <button type="button"  (click)="modal_close_add_user()"  class="btn btn-secondary" data-dismiss="modal">Close</button>
        <button [disabled]="!userForm.valid" type="button" (click)="save_new_user()" class="btn btn-primary">Save </button>
      </div>
    </div>
  </div>
</div>
</form>

<!--              Add user modal      end     -->



