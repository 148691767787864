


<nav aria-label="breadcrumb">
  <ol class="breadcrumb">
    <li class="breadcrumb-item"><a href="/#/dash">Home</a></li>
    <li class="breadcrumb-item"><a href="/#/dash">Dash</a></li>
    <li class="breadcrumb-item active" aria-current="page">View Active Customer list</li>
  </ol>
</nav>








<div *ngIf="view">

  <span>L </span>
  <span style="--delay: 0.1s">o</span>
  <span style="--delay: 0.3s">a</span>
  <span style="--delay: 0.4s">d</span>
  <span style="--delay: 0.5s">i</span>
  <span style="--delay: 0.6s">n</span>
  <span style="--delay: 0.7s">g</span>

</div>








  <div  *ngIf="!view" class="container">


    <table class="table table-bordered table-striped table-responsive-stack" >
       <thead class="thead-dark">
          <tr>
             <th>Name</th>
             <th>Location</th>
             <th>Phone</th>
             <th>GSTIN</th>
          </tr>
       </thead>
       <tbody>
          <tr *ngFor="let item of _Shop; let k=index " >
             <td>{{item.SHOP_NAME}}</td>
             <td>{{item.SHOP_LOCATION}}</td>
             <td>{{item.SHOP_PHONE}}</td>
             <td>{{item.SHOP_GSTIN}}</td>
          </tr>

       </tbody>
    </table>
    </div>









