

<nav aria-label="breadcrumb">
  <ol class="breadcrumb">
    <li class="breadcrumb-item"><a href="/#/dash">Home</a></li>
    <li class="breadcrumb-item"><a href="/#/dash">Order</a></li>
    <li class="breadcrumb-item active" aria-current="page">Edit Order</li>
  </ol>
</nav>









<form [formGroup]="order_detail_Form">

<div class="container py-3">
  <div class="row">
    <div class="col-md-12">

<div class="card card-outline-secondary">

  <div class="card-body">
    <div class="row mt-4">





      <div class="col-sm-3 pb-3">
        <label for="exampleAccount">Date</label>
        <input
        type="date"
        class="form-control"
        formControlName="_ORDER_DATE"
        aria-label="Small"
        aria-describedby="inputGroup-sizing-sm"
      />
      </div>
      <div class="col-sm-5 pb-3">
        <label for="exampleCtrl"> Shop Search </label>


        <input  class="form-control" style="width: 100%;"
          #bwin
          (keyup)="change_shop_name(bwin.value)"
          (blur)="change_shop_name(bwin.value)"
          auto-complete
          list-formatter=" SHOP_NAME -- SHOP_LOCATION"
          loading-text="loading"
          [(ngModel)]="SHOP_SELECTOR" [ngModelOptions]="{standalone: true}"
          display-property-name="ID"
          aria-label="Small"
          aria-describedby="inputGroup-sizing-sm"
          [source]="mySource"
        />
      </div>


      <div class="col-sm-4 pb-3">
        <label for="exampleAmount">Shop Name</label>
        <div class="input-group">

          <input
          type="text"
          class="form-control"
          formControlName="_SHOP_NAME"
          aria-label="Small"
          aria-describedby="inputGroup-sizing-sm"/>

        </div>
      </div>


      <div class="col-sm-6 pb-3">
        <label for="exampleFirst">  Shop Location </label>
        <input
        type="text"
        class="form-control"
        formControlName="_SHOP_LOCATION"
        aria-label="Small"
        aria-describedby="inputGroup-sizing-sm"/>
      </div>










      <div class="col-sm-3 pb-3">
        <label for="exampleSt"> Select 1 </label> <select #koo (change)='oncatSelected(koo.value)' formControlName="CAT_NAME"  class="form-control custom-select" >
          <option class="text-white bg-warning">
            Pick a {{cat_one_name}}
          </option>


            <option *ngFor="let type of cat_list" [value]="type">{{type}}</option>


        </select>
      </div>

      <div class="col-sm-3 pb-3">
        <label for="exampleSt"> Select 2 </label>
         <select   #kiisi  (change)='onsubcatSelected(kiisi.value)' formControlName="SUB_CAT_NAME"   class="form-control custom-select">
          <option class="text-white bg-warning">
            Pick a {{cat_two_name}}
          </option>
            <option *ngFor="let tu of sub_cat_list"  [value]="tu" >{{tu}}</option>
        </select>
      </div>


      <div class="col-sm-3 pb-3">
        <label for="exampleSt"> Select 3 </label> <select  #misudb  (change)='onsub_sub_catSelected(misudb.value)'   formControlName="_SUB_SUB_CAT_NAME" class="form-control custom-select" >
          <option class="text-white bg-warning">
            Pick a {{cat_three_name}}
          </option>
            <option *ngFor="let typ of sub_sub_cat_list" [value]="typ">{{typ}}</option>
          </select>
      </div>

      <div class="col-sm-6 pb-3">
        <label for="exampleLast">  Pick Product </label>


        <input class="form-control" style="width: 100%;"
          #bwin2
          (keyup)="change_product_name(bwin2.value)"
          (blur)="change_product_name(bwin2.value)"
          auto-complete
          list-formatter="ID -- PRODUCT_NAME --"
          loading-text="loading"
          formControlName="_PRODUCT_NAME"
          display-property-name="PRODUCT_NAME"
          aria-label="Small"
          aria-describedby="inputGroup-sizing-sm"
          [source]="pdtSource"/>


      </div>



      <div class="col-sm-3 pb-3">
        <label for="exampleAmount">Quantiy</label>
        <div class="input-group">

          <input
          type="number"
          class="form-control"
          formControlName="_QTY"
          aria-label="Small"
          aria-describedby="inputGroup-sizing-sm"/>

        </div>
      </div>



      <div class="col-md-6 pb-3">
        <label for="exampleMessage">Remarks</label>
        <textarea  formControlName="_REMARK" class="form-control" id="complexExampleMessage" rows="3"></textarea>
          <small class="text-muted">Add any notes here.</small>
      </div>



    </div>
  </div>
  <div class="card-footer">
    <div class="float-right">
      <button  [disabled]="!order_detail_Form.valid"  type="button"   (click)="add_detail()"  class="btn btn-success btn-lg btn-block">
        Add To List
      </button>
    </div>
  </div>
</div>
</div>
</div>
</div>


</form>





  <div class="row" *ngIf="this.hs.o_m.varibs.i != -1">

    <div class="col-12 col-sm-3">

      <button    type="button"   (click)="Show_hide()"  class="btn">
        Show/Hide
      </button>

    </div>


  </div>














  <div *ngIf="show_hide && this.hs.o_m.varibs.i != -1" class="table-responsive-sm">

    <div class="container">
      <div class="row py-2">
        <div class="col-12">

  <table  style="width: 100%;
    color: #dfd9d9;
    font-size: 10pt;
    font-style: normal;
    text-align: center;background-color:#091c29;
    border-collapse: collapse;">
      <tr style="color: #030805;
      background-color: silver;
      border-bottom: 2px solid black;" >
          <th>SI</th>

          <th>item</th>
          <th>{{cat_one_name}}</th>
          <th>{{cat_two_name}}</th>
          <th>{{cat_three_name}}</th>
          <th>Qty</th>
          <th> Edit  </th>
          <th> Delete </th>


        </tr>
      <tr *ngFor="let item of hs.o_m.items; let k=index " height="10"  style="border-bottom: 1px solid ghostwhite;" >
        <td height="15" style="width:5%;">   {{item.SI_NO}} </td>


        <td> {{ item.PRODUCT_NAME }}  </td>
        <td> {{ item.CAT_NAME}}  </td>
        <td> {{ item.SUB_CAT_NAME }} </td>
      <td> {{ item.SUB_SUB_CAT_NAME }} </td>
      <td> {{item.QTY | number : '1.2-2'}} </td>

        <td height="8" style="width:5%;" class="tds_plus">

        <button (click)="editItem(k)" (keydown.ArrowDown)="editItem(k)"
         class="btn-outline-dark" style="background-color:violet">
             <span class="navbar-toggler-icon">
            <img src="../../assets/edit.svg" style="width: 18px;
          height: 22px;" /> </span>
          </button>
      </td>
        <td height="8" style="width:5%;" class="tds_plus" >
        <button (click)="RemoveItem(k)" class="btn-outline-dark"  style="background-color:salmon" >
           <span class="navbar-toggler-icon">
            <img src="../../assets/delete.svg" style="width: 18px;
          height: 22px;" /> </span></button>
        </td>

      </tr>

    </table>
    </div>
    </div>
    </div>





  </div>

  <br>

  <div *ngIf="show_hide && this.hs.o_m.varibs.i != -1"  class="container">
    <div class="row">
      <div class="col-sm-3 col-6">
        <button   type="button"   (click)="submit_fresh_order()" class="btn btn-primary btn-lg btn-block">
          Submit
        </button>
      </div>

      <div class="col-sm-3 col-6">
        <button   type="button"   (click)="put_to_hold()" class="btn btn-warning btn-lg btn-block">
          Put to Hold
        </button>
      </div>

   </div>
   <br>
  </div>


  <!-- <div class="row">

  </div> -->




   <!--   product updation modal  -->

  <form [formGroup]="updationForm">
    <div id="edit_updation_modal" class="modal" tabindex="-1" role="dialog">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">



            <h5 class="modal-title">Edit Details</h5>
            <button type="button" (click)="modal_close_edit_details()" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>




          </div>



          <div class="modal-body">

            <div class="col-12 col-sm-12">
              <div class="input-group input-group-sm mb-6">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="inputGroup-sizing-sm">
                    Select {{cat_one_name}}
                  </span>
                </div>

                 <select   #migi  (change)='oncatSelected(migi.value)' formControlName="_CAT_U"   id="bn" class="form-control">
                  <option *ngFor="let type of cat_list" [value]="type">{{type}}</option>
                </select>

            </div>

            </div>


    <div class="col-12 col-sm-12">
      <div class="input-group input-group-sm mb-6">
        <div class="input-group-prepend">
          <span class="input-group-text" id="inputGroup-sizing-sm">
            Select {{cat_two_name}}
          </span>
        </div>

         <select #misudti  (change)='onsubcatSelected(misudti.value)'  formControlName="_SUB_CAT_U"   id="sz" class="form-control">
          <option *ngFor="let type of sub_cat_list" [value]="type">{{type}}</option>
        </select>

    </div>

    </div>



    <div class="col-12 col-sm-12">
      <div class="input-group input-group-sm mb-6">
        <div class="input-group-prepend">
          <span class="input-group-text" id="inputGroup-sizing-sm">
            Select {{cat_three_name}}
          </span>
        </div>

         <select   #misudt  (change)='onsub_sub_catSelected_on_edit(misudt.value)'   formControlName="_SUB_SUB_CAT_U"  id="clr" class="form-control">
          <option *ngFor="let type of sub_sub_cat_list" [value]="type">{{type}}</option>
        </select>

    </div>

    </div>




  <div class="col-12 col-sm-12">

    <div class="input-group input-group-sm mb-6">
      <div class="input-group-prepend">
        <span class="input-group-text" id="inputGroup-sizing-sm">
          Select Product Name
        </span>
      </div>

      <input class="form-control" style="width: 100%;"
             (valueChanged)="change_product_name_on_edit($event)" formControlName="_PDT_NAME_U"
            (customSelected)="customCallback($event)"
            auto-complete
            list-formatter="-- PRODUCT_NAME --"
            loading-text="loading"
            display-property-name="PRODUCT_NAME"
            aria-label="Small"
            aria-describedby="inputGroup-sizing-sm"

            [source]="pdtSource"/>


  </div>

  </div>





    <div class="col-12 col-sm-12">
      <div class="input-group input-group-sm mb-6">
        <div class="input-group-prepend">
          <span class="input-group-text" id="inputGroup-sizing-sm">
            Select Qty
          </span>
        </div>

        <input  formControlName="_QTY_U"
        type="number"
        class="form-control"
        aria-label="Small"
        aria-describedby="inputGroup-sizing-sm"/>

    </div>
    </div>

    <div class="col-12 col-sm-12">
      <div class="input-group input-group-sm mb-6">
        <div class="input-group-prepend">
          <span class="input-group-text" id="inputGroup-sizing-sm">
            Remarks
          </span>
        </div>

        <input  formControlName="_REMARK_U"
        type="text"
        class="form-control"
        aria-label="Small"
        aria-describedby="inputGroup-sizing-sm"/>

    </div>
    </div>






   </div>



          <div class="modal-footer">
            <button type="button"  (click)="modal_close_edit_details()"  class="btn btn-secondary" data-dismiss="modal">Close</button>
            <button [disabled]="!updationForm.valid" type="button" (click)="update_edited_details()" class="btn btn-primary">Update </button>
          </div>


        </div>
      </div>
    </div>
    </form>











