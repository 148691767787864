
import { Component, OnInit, OnDestroy } from '@angular/core';

import { Router } from '@angular/router';

import { FormGroup, FormControl, Validators } from '@angular/forms';

import { Http_client_Service } from '../../Service/http.service';

@Component({
  selector: 'app-main-settings-page',
  templateUrl: './main_page.html',
  styleUrls: ['./main_page.css']
})
export class Setting_mainpage_Component implements OnInit {





  Add_product_modal_3: any;
  Add_user_modal_3 : any;
  Add_shop_modal : any;


  delete_cat_modal : any;
  delete_sub_cat_modal: any;
  delete_sub_sub_cat_modal_3: any;
  add_cat_modal : any;
  add_sub_cat_modal : any;
  shop_updation_modal : any;
  add_sub_sub_cat_modal : any;
  delete_sub_sub_cat_modal : any;

  delete_product_modal_3: any;
  manage_user_modal_3: any;
  Add_company_modal_3: any;
  manage_company_modal_3 : any;
  cat_name : any;
  options: any[] = ['User', 'Shop']

  cat_list: any[] = [];

  sub_cat_list: any[] = [];

  sub_sub_cat_list: any[] = [];

  sub_cat_list_inde : any[] = [];
  sub_sub_cat_list_inde : any[] = [];

  user_id: any;
  com_id: any;
  product_list: any[] = []
  array_of_users: any[] = [];

  cat_one_name: string = '';
  cat_two_name: string = '';
  cat_three_name: string = '';
  com_name : string = '';

  mySource : any [] = [];


  company_Form = new FormGroup({
    COMPANY_NAME: new FormControl('', [Validators.required, Validators.minLength(1)]),
    COMPANY_ADDRESS: new FormControl('', [Validators.required, Validators.minLength(1)]),
    COMPANY_CITY: new FormControl('', [Validators.required, Validators.minLength(1)]),
    COMPANY_PH: new FormControl('', [Validators.required, Validators.minLength(1)]),
    COM_ID: new FormControl(''),
    USER_ID: new FormControl(''),
    CAT_ONE_NAME: new FormControl(''),
    CAT_TWO_NAME: new FormControl(''),
    CAT_THREE_NAME: new FormControl(''),
  });

  shopForm = new FormGroup({

    SHOP_NAME: new FormControl('',Validators.required),
    SHOP_GSTIN: new FormControl(''),
    SHOP_LOCATION: new FormControl('',Validators.required),
    SHOP_PHONE: new FormControl('', [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]),
    SHOP_EMAIL: new FormControl(''),
    COM_ID :  new FormControl(''),

  });

  shop_updation_Form = new FormGroup({

    SHOP_NAME: new FormControl('',Validators.required),
    SHOP_GSTIN: new FormControl(''),
    SHOP_LOCATION: new FormControl(''),
    SHOP_PHONE: new FormControl(''),
    SHOP_EMAIL: new FormControl(''),
    ID: new FormControl(''),
    ID_mock : new FormControl('')

  });


  company_manage_Form  = new FormGroup({
    COMPANY_NAME: new FormControl('', [Validators.required, Validators.minLength(1)]),
    COMPANY_ADDRESS: new FormControl('', [Validators.required, Validators.minLength(1)]),
    COMPANY_CITY: new FormControl('', [Validators.required, Validators.minLength(1)]),
    COMPANY_PH: new FormControl('', [Validators.required, Validators.minLength(1)]),
    COM_ID: new FormControl(''),

    CAT_ONE_NAME: new FormControl(''),
    CAT_TWO_NAME: new FormControl(''),
    CAT_THREE_NAME: new FormControl(''),
  });

  categoryForm = new FormGroup({
    CATEGORY_NAME: new FormControl('', [Validators.required, Validators.minLength(1)]),
    COM_ID: new FormControl(''),
  });


  sub_cat_Form = new FormGroup({
    SUB_CATEGORY_NAME: new FormControl('', [Validators.required, Validators.minLength(1)]),
    CAT_NAME: new FormControl('', [Validators.required]),
    COM_ID: new FormControl(''),
  })


  sub_sub_cat_Form = new FormGroup({
    SUB_SUB_CAT_NAME: new FormControl('', [Validators.required, Validators.minLength(1)]),
    CAT_NAME: new FormControl('', [Validators.required]),
    SUB_CAT_NAME: new FormControl('', [Validators.required]),
    COM_ID: new FormControl(''),
  })

  sub_sub_cat_del_Form = new FormGroup({
    SUB_SUB_CAT_NAME: new FormControl('', [Validators.required, Validators.minLength(1)]),
    COM_ID: new FormControl(''),
  })



  productForm = new FormGroup({
    PRODUCT_NAME: new FormControl('', [Validators.required, Validators.minLength(3)]),
    CAT_NAME: new FormControl('', [Validators.required]),
    SUB_CAT_NAME: new FormControl('', [Validators.required]),
    SUB_SUB_CAT_NAME: new FormControl('', [Validators.required]),
    COM_ID: new FormControl(''),
  });



  cat_del_Form = new FormGroup({

    CAT_NAME: new FormControl('', [Validators.required]),
    COM_ID: new FormControl(''),

  });
  sub_cat_del_Form = new FormGroup({

    SUB_CAT_NAME: new FormControl('', [Validators.required]),
    COM_ID: new FormControl(''),

  });




  userForm = new FormGroup({


    USER_NAME: new FormControl('', [Validators.required, Validators.minLength(3)]),
    EMAIL: new FormControl('', [Validators.required, Validators.email]),
    PASSWORD: new FormControl('', [Validators.required, Validators.minLength(1)]),
    USER_ROLE: new FormControl('', [Validators.required]),
    COM_ID: new FormControl(''),
    ID : new FormControl(''),


  });





  constructor(private router: Router, private hs: Http_client_Service) { }

  ngOnInit() {



   this.add_cat_modal = document.getElementById("add_cat_modal");
   this.delete_cat_modal = document.getElementById("delete_cat_modal");

   this.add_sub_cat_modal = document.getElementById("add_sub_cat_modal");
   this.delete_sub_cat_modal = document.getElementById("delete_sub_cat_modal_");

   this.add_sub_sub_cat_modal = document.getElementById("add_sub_sub_cat_modal");
   this.delete_sub_sub_cat_modal = document.getElementById("delete_sub_sub_cat_modal");

   this.Add_shop_modal =  document.getElementById("add_shop_modal");
   this.shop_updation_modal = document.getElementById("update_shop_modal");



    this.Add_product_modal_3 = document.getElementById("add_product_modal");
    this.Add_company_modal_3 = document.getElementById("company_add_modal");
    this.Add_user_modal_3 = document.getElementById("add_user_modal");
    this.delete_sub_sub_cat_modal_3 = document.getElementById("delete_color_modal");


    this.delete_product_modal_3 = document.getElementById("delete_product_modal");
    this.manage_user_modal_3 = document.getElementById("manage_user_modal");
    this.manage_company_modal_3 = document.getElementById("manage_company_modal");
    this.hs.is_shop_updation = false;
    this.com_id = localStorage.getItem('com_id');
    this.user_id = localStorage.getItem('user_id');
    if (this.com_id == 0) {
      this.add_company();
    }
    else {
      this.get_company_dets(this.com_id)
    }


  }

  get_company_dets(id: number) {
    this.hs.get_company_dets(id)
      .subscribe((jsonData: any) => {
        this._get_res_company_details(jsonData)
      }, (err: any) => console.error(err),
      );

  }
  _get_res_company_details(data: any) {
    console.log('data', data);
    this.com_name = data.COMPANY_NAME
    this.cat_one_name = data.CAT_ONE_NAME;
    this.cat_two_name = data.CAT_TWO_NAME;
    this.cat_three_name = data.CAT_THREE_NAME;
  }

  oncatSelected(ev: any) {
    console.log('ev', ev);
    this.com_id = localStorage.getItem('com_id');
    console.log('com_id',  this.com_id );
    this.cat_name = ev
    this.productForm.patchValue({ COM_ID: this.com_id , CAT_NAME : ev  });
    this.hs.get_all_sub_cat_on_change_cat(this.productForm.value)
      .subscribe((jsonData: any) => {
        this._get_res_sub_cats_on_cat_change(jsonData)
      }, (err: any) => console.error(err),
      );
  }
  _get_res_sub_cats_on_cat_change(sub_cats: any) {

    console.log('data', sub_cats);
    while (this.sub_cat_list.length) {
      this.sub_cat_list.pop();
    }
    for (var j = 0; j < sub_cats.length; j++) {
      this.sub_cat_list[j] = sub_cats[j].SUB_CATEGORY_NAME
    }

  }
  onsubcatSelected(ev: any) {
    console.log('ev', ev);
    this.com_id = localStorage.getItem('com_id');
    this.sub_sub_cat_Form.patchValue({ CAT_NAME : this.cat_name, SUB_CAT_NAME: ev, COM_ID: this.com_id });
    this.hs.get_all_sub_sub_cat_on_change_sub_cat(this.sub_sub_cat_Form.value)
      .subscribe((jsonData: any) => {
        this._get_res_sub_sub_cats_on_cat_change(jsonData)
      }, (err: any) => console.error(err),
      );
  }
  _get_res_sub_sub_cats_on_cat_change(sub_sub_cats: any) {
    console.log('evsub_sub_cats', sub_sub_cats);
    while (this.sub_sub_cat_list.length) {
      this.sub_sub_cat_list.pop();
    }
    for (var j = 0; j < sub_sub_cats.length; j++) {
      this.sub_sub_cat_list[j] = sub_sub_cats[j].SUB_SUB_CAT_NAME
    }
  }
  myuser_search_back(ev:any)
  {
     this.userForm.patchValue({ USER_NAME: ev.USER_NAME, USER_ROLE: ev.USER_ROLE, PASSWORD: ev.PASSWORD , EMAIL : ev.EMAIL , COM_ID : ev.COM_ID , ID : ev.id })
  }


  customCallback(key: any) {
    alert('Wrong Selection');
  }

  add_company() {
    this.Add_company_modal_3.style.display = "block";
  }

  Save_company() {

    console.log(this.company_Form.value);
    this.user_id = localStorage.getItem('user_id');
    this.com_id = localStorage.getItem('com_id');
    if(this.com_id > 0)
    {
      alert('To add more companiy Pls Contact Support')
    }
    else
    {
      this.company_Form.patchValue({ USER_ID: this.user_id })
      this.hs.add_company_details(this.company_Form.value)
        .subscribe((jsonData: any) => {
          this._get_res_(jsonData)
        }, (err: any) => console.error(err),

        );
    }


  }

  _get_res_(data: any) {
    console.log('data', data)
    alert(data.msg);
    localStorage.setItem('com_id',data.id);
    this.router.navigate(['/dash']);
  }



  onOptionsSelected(ev: any) {
    console.log('ev', ev);

    this.sub_sub_cat_Form.patchValue({ CAT_NAME: ev, COM_ID: this.hs.com_id });
    this.hs.get_all_sub_cat_on_change_cat(this.sub_sub_cat_Form.value)
      .subscribe((jsonData: any) => {
        this._get_res_19(jsonData)
      }, (err: any) => console.error(err),
      );
  }

  _get_res_19(sub_cats: any) {
    console.log('data', sub_cats);
    while (this.sub_cat_list.length) {
      this.sub_cat_list.pop();
    }
    for (var j = 0; j < sub_cats.length; j++) {
      this.sub_cat_list[j] = sub_cats[j].SUB_CATEGORY_NAME
    }

  }

  save_sub_sub_cat() {
    this.sub_sub_cat_Form.patchValue({ COM_ID: this.hs.com_id });
    this.hs.add_new_sub_sub_category(this.sub_sub_cat_Form.value)
      .subscribe((jsonData: any) => {
        this._get_res_25(jsonData)
      }, (err: any) => console.error(err),

      );

  }
  _get_res_25(data: any) {
    console.log('data', data);
    alert(data.msg);
    this.sub_sub_cat_Form.controls.SUB_SUB_CAT_NAME.reset();
  }


  manage_company(){
    this.manage_company_modal_3.style.display="block";
    this.get_company_details_all(this.com_id)
  }

  get_company_details_all(id:number)
  {
    this.hs.get_company_dets(id)
    .subscribe((jsonData: any) => {
      this._get_res_all_com_dets(jsonData)
    }, (err: any) => console.error(err),
    );

  }

  _get_res_all_com_dets(data:any)
  {
     this.company_manage_Form.patchValue({COMPANY_NAME: data.COMPANY_NAME,COMPANY_ADDRESS: data.COMPANY_ADDRESS, COMPANY_CITY : data.COMPANY_CITY,
      COMPANY_PH: data.COMPANY_PH, CAT_ONE_NAME : data.CAT_ONE_NAME ,  CAT_TWO_NAME : data.CAT_TWO_NAME , CAT_THREE_NAME : data.CAT_THREE_NAME , COM_ID : data.id })
  }



  update_user_details() {
    this.hs.update_user_details(this.userForm.value)
      .subscribe((jsonData: any) => {
        this._get_res_update_user_details(jsonData)
      }, (err: any) => console.error(err),

      );

  }
  _get_res_update_user_details(result: any) {
    console.log(result)
    alert(result.msg);
    while (this.array_of_users.length) {
      this.array_of_users.pop();
    }

    this.userForm.reset();
    this.modal_close_manage_user();
    this.hs.get_all_users_of_com_id(this.com_id)
      .subscribe((jsonData: any) => {
        this._get_res_9(jsonData)
      }, (err: any) => console.error(err),

      );

  }


  modal_close_manage_user() {
    this.manage_user_modal_3.style.display = "none"
  }

  modal_close_company() {
    this.Add_company_modal_3.style.display = "none";
  }








  close_modal_delete_product() {
    this.delete_product_modal_3.style.display = "none";
  }


  open_modal_add_category() {
    this.add_cat_modal.style.display = "block";
  }

  modal_close_add_cat()
  {
    this.categoryForm.reset();
    this.add_cat_modal.style.display = "none";

  }






  Save_cat_name() {

    this.categoryForm.patchValue({ COM_ID: this.com_id })

    this.hs.add_new_category(this.categoryForm.value)
      .subscribe((jsonData: any) => {
        this._get_res_2(jsonData)
      }, (err: any) => console.error(err),

      );
  }

  Save_sub_cat() {

    this.sub_cat_Form.patchValue({ COM_ID: this.com_id })
    this.hs.add_new_sub_category(this.sub_cat_Form.value)
      .subscribe((jsonData: any) => {
        this._get_res_add_sub_cat(jsonData)
      }, (err: any) => console.error(err),

      );

  }

  _get_res_add_sub_cat(js:any)
  {
    alert(js.msg);
    this.sub_cat_Form.controls.SUB_CATEGORY_NAME.reset();
  }


  add_product() {
    this.productForm.reset();
    this.Add_product_modal_3.style.display = "block";
    this.get_all_cat_list();
  }



  modal_close_add_product() {
    this.Add_product_modal_3.style.display = "none";
  }


  save_new_product() {

    this.hs.add_new_product(this.productForm.value)
      .subscribe((jsonData: any) => {
        this._get_res_2(jsonData)
      }, (err: any) => console.error(err),

      );
  }
  _get_res_2(json: any) {
    alert(json.msg);
    this.productForm.reset();
    this.categoryForm.reset();


  }

  Update_company()
  {

    this.hs.update_company_details(this.company_manage_Form.value)
      .subscribe((jsonData: any) => {
        this._get_res_update_com_dets(jsonData)
      }, (err: any) => console.error(err),

      );
  }
  _get_res_update_com_dets(data:any)
  {
    console.log('data',data);
    alert(data.msg);
    this.company_manage_Form.reset();
    this.modal_close_manage_company();
  }

  update_user() {
    this.manage_user_modal_3.style.display = "block";
    this.hs.get_all_users_of_com_id(this.com_id)
      .subscribe((jsonData: any) => {
        this._get_res_9(jsonData)
      }, (err: any) => console.error(err),

      );

  }
  _get_res_9(data: any) {
    this.array_of_users = data;
  }

  delete_product() {

    this.delete_product_modal_3.style.display = "block";
    this.get_all_products();
  }

  delete_product_from_list() {
    this.hs.delete_product(this.productForm.value)
      .subscribe((jsonData: any) => {
        this._get_res_7(jsonData)
      }, (err: any) => console.error(err),

      );
  }

  _get_res_7(data: any) {
    alert(data.msg);
    this.productForm.reset();
    while (this.product_list.length) {
      this.product_list.pop();
    }

    this.get_all_products();
  }

  get_all_products() {
    this.hs.get_all_products()
      .subscribe((jsonData: any) => {
        this._get_res_6(jsonData)
      }, (err: any) => console.error(err),

      );
  }

  _get_res_6(products: any) {

    console.log('pdts', products);
    while (this.product_list.length) {
      this.product_list.pop();
    }

    for (var j = 0; j < products.length; j++) {
      this.product_list[j] = products[j].PRODUCT_NAME
    }


  }


  open_modal_delete_cat() {

    this.delete_cat_modal.style.display = "block";
    this.get_all_cat_list();
  }

  close_modal_delete_cat()
  {
    this.delete_cat_modal.style.display = "none";
  }


  get_all_cat_list() {
    this.hs.com_id = localStorage.getItem('com_id');
    this.hs.get_all_cats(this.hs.com_id)
      .subscribe((jsonData: any) => {
        this._get_res_cats(jsonData)
      }, (err: any) => console.error(err),

      );
  }
  _get_res_cats(cats: any) {
    while (this.cat_list.length) {
      this.cat_list.pop();
    }
    for (var j = 0; j < cats.length; j++) {
      this.cat_list[j] = cats[j].CATEGORY_NAME
    }
  }

  modal_close_manage_company()
  {
    this.manage_company_modal_3.style.display = "none"
  }




  get_all_sub_cat_list_inde()
  {
    this.hs.get_all_sub_cats_inde(this.com_id)
    .subscribe((jsonData: any) => {
      this._get_res_get_all_sub_cats_inde(jsonData)
    }, (err: any) => console.error(err),

    );
  }
  _get_res_get_all_sub_cats_inde(data:any)
  {
    console.log(data);

    while (this.sub_cat_list_inde.length) {
      this.sub_cat_list_inde.pop();
    }
    for (var j = 0; j < data.length; j++) {
      this.sub_cat_list_inde[j] = data[j].SUB_CATEGORY_NAME
    }

  }


  get_all_sub_sub_cat_list_inde()
  {
    this.hs.get_all_sub_sub_cats_inde(this.com_id)
    .subscribe((jsonData: any) => {
      this._get_res_get_all_sub_sub_cats_inde(jsonData)
    }, (err: any) => console.error(err),

    );
  }
  _get_res_get_all_sub_sub_cats_inde(sub_sub_cats:any)
  {


    while (this.sub_sub_cat_list_inde.length) {
      this.sub_sub_cat_list_inde.pop();
    }
    for (var j = 0; j < sub_sub_cats.length; j++) {
      this.sub_sub_cat_list_inde[j] = sub_sub_cats[j].SUB_SUB_CAT_NAME
    }

  }




  add_user() {
    this.Add_user_modal_3.style.display = "block";
  }
  modal_close_add_user() {
    this.Add_user_modal_3.style.display = "none";
  }
  save_new_user() {
    this.hs.com_id = localStorage.getItem('com_id');
    this.userForm.patchValue({ COM_ID: this.hs.com_id })
    console.table(this.userForm.value);


    this.hs.add_new_user(this.userForm.value)
      .subscribe((jsonData: any) => {
        this._get_res_save_user(jsonData)
      }, (err: any) => console.error(err),

      );
  }

  _get_res_save_user(js:any)
  {
    alert(js.msg);
    this.userForm.reset();
    this.modal_close_add_user();
  }



  add_shop() {

    this.Add_shop_modal.style.display = "block";
   // this.router.navigate(['/add-shop'])
  }


  delete_sub_cat() {
    this.com_id = localStorage.getItem('com_id');
    this.sub_cat_del_Form.patchValue({ COM_ID : this.com_id})
    this.hs.delete_sub_cat(this.sub_cat_del_Form.value)
    .subscribe((jsonData:any) => { this._get_res_delete_sub_cat(jsonData)
            },(err:any) => console.error(err),

            );
  }

  _get_res_delete_sub_cat(json:any)
  {

    console.log('subss',json)
    alert(json.msg);


  }
  delete_cat() {

    this.com_id = localStorage.getItem('com_id');
    this.categoryForm.patchValue({COM_ID : this.com_id})
     this.hs.delete_cat(this.categoryForm.value)
    .subscribe((jsonData:any) => { this._get_res_delete_cat(jsonData)
            },(err:any) => console.error(err),

            );
  }

  _get_res_delete_cat(json: any) {
    alert(json.msg);
    this.categoryForm.reset();
    this.close_modal_delete_cat();

  }



  delete_sub_sub_cat()
  {
       this.com_id = localStorage.getItem('com_id');
       this.sub_sub_cat_del_Form.patchValue({COM_ID : this.com_id})

        this.hs.delete_sub_sub_cat(this.sub_sub_cat_del_Form.value)
        .subscribe((jsonData:any) => { this._get_res_delete_sub_sub_cat(jsonData)
                },(err:any) => console.error(err),

                );

  }
  _get_res_delete_sub_sub_cat(res:any)
  {

    alert(res.msg);
    this.sub_sub_cat_del_Form.reset();
  }













  open_modal_add_sub_cat()
  {
    this.add_sub_cat_modal.style.display = "block";
    this.get_all_cat_list();
  }
  modal_close_sub_cat()
  {
    this.add_sub_cat_modal.style.display = "none";
    this.sub_cat_Form.reset();
  }

  open_modal_delete_sub_cat()
  {
    this.delete_sub_cat_modal.style.display = "block";
    this.get_all_sub_cat_list_inde();
  }

  close_modal_delete_sub_cat()
  {
    this.delete_sub_cat_modal.style.display = "none";
    this.sub_cat_del_Form.reset();
  }


  open_modal_add_sub_sub_cat()
  {
    this.get_all_cat_list();
    this.add_sub_sub_cat_modal.style.display = "block";
    this.sub_sub_cat_Form.reset();
  }
  modal_close_add_sub_sub_cat()
  {
    this.add_sub_sub_cat_modal.style.display = "none";
  }

  open_modal_delete_sub_sub_cat()
  {
    this.delete_sub_sub_cat_modal.style.display = "block";
    this.get_all_sub_sub_cat_list_inde();


  }
  close_modal_delete_sub_sub_cat()
  {
    this.delete_sub_sub_cat_modal.style.display = "none";
  }

  modal_close_add_shop()
  {
     this.Add_shop_modal.style.display = "none";
  }

  post_add_shop()
  {
    console.log(this.shopForm.value);
    this.com_id  =  localStorage.getItem('com_id');
    this.shopForm.patchValue({ COM_ID : this.com_id })
    this.hs.add_new_shop(this.shopForm.value)
    .subscribe((jsonData:any) => { this._get_res_post_shop(jsonData)
            },(err:any) => console.error(err),

            );
  }

  _get_res_post_shop(json:any)
  {
    alert(json.msg);
    this.shopForm.reset();
    this.modal_close_add_shop();
  }


  post_update_shop()
  {
    console.log(this.shop_updation_Form.value);
    this.hs.update_exsiting_shop(this.shop_updation_Form.value)
    .subscribe((jsonData:any) => { this._get_res_shop_updation(jsonData)
            },(err:any) => console.error(err),

            );
  }
  _get_res_shop_updation(json:any)
  {
    alert(json.msg);
    this.shop_updation_Form.reset();

    this.get_all_shop_details();
    this.modal_close_update_shop();
  }


  search_shop(ev:any)
  {

    console.log('ev',ev)
      this.shop_updation_Form.patchValue({SHOP_NAME : ev.SHOP_NAME , SHOP_LOCATION : ev.SHOP_LOCATION ,
         SHOP_GSTIN :   ev.SHOP_GSTIN  , SHOP_PHONE :  ev.SHOP_PHONE , SHOP_EMAIL: ev.SHOP_EMAIL , ID : ev.ID })


}

update_shop() {
  this.hs.is_shop_updation = true;
  this.shop_updation_modal.style.display = "block";
  this.get_all_shop_details();
}


get_all_shop_details()
{
  this.com_id  =  localStorage.getItem('com_id');
  this.hs.get_all_shop_details(this.com_id)
  .subscribe((jsonData:any) => { this._get_all_shop_details(jsonData)
          },(err:any) => console.error(err),

          );
}

_get_all_shop_details(shops:any)
{
  this.mySource = shops;
}

modal_close_update_shop()
{
  this.shop_updation_modal.style.display = 'none';
}


}
