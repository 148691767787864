<section>
  <br>

  <form [formGroup]="registerForm" (ngSubmit)="onSubmit()">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 col-md-8 col-lg-8 col-xl-6">
        <div class="row">
          <div class="col text-center">
            <h2>OFOS</h2>
            <p class="text-h3"> Register </p>
          </div>
        </div>
        <!-- <div class="row align-items-center">
          <div class="col mt-4">
            <input type="text" class="form-control" formControlName="companyName" placeholder="Company Name">
          </div>
        </div>
        <div class="row align-items-center mt-4">
          <div class="col">
            <input type="email" class="form-control" formControlName="email"  placeholder="Email">
          </div>
        </div> -->


        <div class="form-group">
          <label>Name</label>
          <input type="text" formControlName="name" class="form-control"
          [ngClass]="{ 'is-invalid': submitted && f.name.errors }" />
          <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
              <div *ngIf="f.name.errors.required">Name is required</div>
          </div>
      </div>

      <div class="form-group">
          <label>Email</label>
          <input type="text" formControlName="email" class="form-control"
          [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
          <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
              <div *ngIf="f.email.errors.required">Email is required</div>
              <div *ngIf="f.email.errors.email">Email must be a valid email address</div>
          </div>
      </div>


        <!-- <div class="row align-items-center mt-4">
          <div class="col-sm-12">
            <input type="password" class="form-control" formControlName="password" placeholder="Password">
            <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
              <div *ngIf="f.password.errors.required">Password is required</div>
              <div *ngIf="f.password.errors.minlength">Password must be at least 6 characters</div>
          </div>

          </div>
        </div>
        <div class="row align-items-center mt-4">
          <div class="col-sm-12">
            <input type="password" class="form-control" formControlName="confirmPassword" placeholder="Confirm Password">
          </div>

        </div> -->




        <div class="form-group">
          <label>Password</label>
          <input type="password" formControlName="password" class="form-control"
          [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
          <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
              <div *ngIf="f.password.errors.required">Password is required</div>
              <div *ngIf="f.password.errors.minlength">Password must be at least 6 characters</div>
          </div>
      </div>
      <div class="form-group">
          <label>Confirm Password</label>
          <input type="password" formControlName="confirmPassword"
           class="form-control" [ngClass]="{ 'is-invalid': submitted && f.confirmPassword.errors }" />
          <div *ngIf="submitted && f.confirmPassword.errors" class="invalid-feedback">
              <div *ngIf="f.confirmPassword.errors.required">Confirm Password is required</div>
              <div *ngIf="f.confirmPassword.errors.mustMatch">Passwords must match</div>
          </div>
      </div>






        <div class="row justify-content-start mt-4">
          <div class="col">
            <div class="form-check">
              <label class="form-check-label">
                <input type="checkbox"  formControlName="acceptTerms" class="form-check-input">
                I Read and Accept <a href="/#">Terms and Conditions</a>
              </label>
            </div>

            <button  class="btn btn-primary mt-4">Submit</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  </form>
</section>
