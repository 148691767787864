
export class order_master {
  constructor(

  public  heads:_head,
  public  items:_items[],
  public  varibs:_varibs,





  ) {} }

  export class _varibs {
    constructor(

    public  i:number,
    public  k:number,




    ) {} }




  export class _items
  {


    constructor(
   public SI_NO : number,
   public PRODUCT_NAME : string,
   public PRO_ID: number,
   public QTY: number,
   public UNIT: string,
   public CAT_NAME : string,
   public SUB_CAT_NAME : string,
   public SUB_SUB_CAT_NAME : string,
   public BAR_CODE:string,
   public ORDER_NO: number,
   public ORDER_DATE : Date,
   public MODE_ : string,
   public REMARKS : string



    ) {}

  }


export class _head {
  constructor(

 public ORDER_PERSON_ID : number,
 public SHOP_ID : string,
 public SHOP_NAME : string,
 public SHOP_LOCATION : string,
 public ORDER_DATE : Date,
 public EMPLOYEE_ID : number,
 public STATUS_FLAG : number,
 public MODE_ : string,
 public ORDER_ID : number,
 public COM_ID : number

  ) {}

}



export class figure {
  constructor(

 public   number_to_convert: number,
 public   fraction : number,
 public   msg: string




  ) {} }



  // STATUS FLAG  : 0  -> ON HOLD 1  -> OPEN 2 -> ORDER CANCELED  3 -> ORDER REJECTED  4 -> PROCESSING  5 -> EXECUTED
