
import { Component, OnInit, OnDestroy } from '@angular/core';
import {FormBuilder, FormGroup, FormControl,Validators } from '@angular/forms';
import {Router} from '@angular/router';
import { Http_client_Service } from '../Service/http.service';
import { NgxPermissionsService } from 'ngx-permissions';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({ selector: 'app-login',
 templateUrl: './login.html',
 styleUrls: ['./login.css']
 })

export class LoginComponent implements OnInit {



    loginForm = this.fb.group({
      User_mail : [''],
      Pass_word : [''],

    });


    constructor(private fb : FormBuilder,private rter: Router,private hs: Http_client_Service,private ps: NgxPermissionsService,private _snackBar: MatSnackBar) { }

    ngOnInit() {
                      this.ps.flushPermissions();

               }




    onSubmit(customerData : any) {

      this.hs.login(customerData)
        .subscribe((jsonData:any) => { this._get_res_login(jsonData)
          },(err:any) => console.error(err),

          );

        }

        _get_res_login(data:any)
        {
          console.log('data',data)

           if(data.success == true)
           {
             this.hs.user_id = data.user_id;
             this.hs.user_role = data.role;
             this.hs.com_id = data.com_id;

             this.openSnackBar(data.role , ' Logged In')
             localStorage.setItem('user_role', data.role);
             localStorage.setItem('user_id', data.user_id);
             localStorage.setItem('com_id', data.com_id);
             localStorage.setItem('user_name',data.user_name)
             this.ps.loadPermissions([this.hs.user_role]);

            this.rter.navigate(['/dash']);
           }
           else
           {
              alert(data.msg)
           }


        }


        openSnackBar(message: string, action: string) {
          this._snackBar.open(message, action, {
            duration: 2000,
            horizontalPosition: 'center',
            verticalPosition: 'top'
          });
        }









    reset()
    {
      this.loginForm.reset();
    }

}
