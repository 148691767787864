<section style="height:100%;padding-top:15%;padding-bottom: 15%;">
<div class="global-container">
	<div class="card login-form">
	<div class="card-body">
		<h3 class="card-title text-center">Log in to OFOS</h3>
		<div class="card-text">
			<!--
			<div class="alert alert-danger alert-dismissible fade show" role="alert">Incorrect username or password.</div> -->
			<form [formGroup]="loginForm" (ngSubmit)="onSubmit(loginForm.value)" >
				<!-- to error: add class "has-danger" -->
				<div class="form-group">
					<label for="exampleInputEmail1">Email</label>
					<input type="email" autocomplete="off" class="form-control form-control-sm" id="exampleInputEmail1"  formControlName="User_mail" aria-describedby="emailHelp">
				</div>
				<div class="form-group">
					<label for="exampleInputPassword1">Password</label>
					<input type="password" class="form-control form-control-sm" id="exampleInputPassword1" formControlName="Pass_word" >
				</div>
				<button type="submit" class="btn btn-primary btn-block">Sign in</button>

				<div class="sign-up">
					Don't have an account? <a href="#/new-user">Create One</a>
				</div>
			</form>
		</div>
	</div>
</div>
</div>
</section>















