

<nav *ngxPermissionsOnly="['Admin']" class="navbar navbar-expand-lg navbar-light bg-light">
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo03" aria-controls="navbarTogglerDemo03" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
  <a class="navbar-brand" href="#/dash">OFOS</a>

  <div class="collapse navbar-collapse" id="navbarTogglerDemo03">
    <ul class="navbar-nav mr-auto mt-2 mt-lg-0">

      <!-- <li class="nav-item active">
        <a class="nav-link" href="#">Home <span class="sr-only">(current)</span></a>
      </li> -->



      <li class="nav-item">
        <a class="nav-link" (click)="view_orders()"> View Orders </a>
      </li>

      <li class="nav-item">
        <a class="nav-link" href="/#/settings">Settings</a>
      </li>


      <li class="nav-item">
        <a class="nav-link" (click)="log_out()" >Log Out</a>
      </li>

    </ul>

    <span class="navbar-text" style="color:purple;">
      Welcome  {{user_name}}
    </span>

  </div>
</nav>




<nav *ngxPermissionsOnly="['User','Shop']" class="navbar navbar-expand-lg navbar-light bg-light">
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo03"
   aria-controls="navbarTogglerDemo03" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
  <a class="navbar-brand" href="#/dash">OFOS</a>

  <div class="collapse navbar-collapse" id="navbarTogglerDemo03">
    <ul class="navbar-nav mr-auto mt-2 mt-lg-0">

      <li class="nav-item">
        <a class="nav-link" href="/#/order-new">New Order</a>
      </li>



      <li class="nav-item">
        <a class="nav-link" href="/#/order-view-active">View Orders</a>
      </li>




      <li class="nav-item">
        <a class="nav-link"  href="/#/login">Log Out</a>
      </li>


    </ul>

  </div>
</nav>











<div class="row" *ngxPermissionsOnly="['Admin']">


        <div class="col-lg-3 col-md-6 col-sm-6">
          <div class="card card-stats">
            <div class="card-body ">
              <div class="row">
                <div class="col-5 col-md-4">
                  <div class="icon-big text-center icon-warning">
                    <span class="nc"> <img src="../../assets/dollr.PNG" alt="Girl in a jacket" style="width:90px;height:80px;"> </span>
                  </div></div>
                  <div class="col-7 col-md-8">
                    <div class="numbers">
                      <p class="card-category">Active Orders</p>
                      <p class="card-title">{{active_order_count}} </p><p></p></div></div></div></div>
                      <div class="card-footer "><hr>
                        <div class="stats">    <button type="button" (click)="view_active_orders()" class="btn btn-light"> <i class="fa fa-eye"></i>  View</button>
                          <button type="button" (click)="get_active_order_count_admin()" class="btn btn-light"> <i class="fa fa-refresh"></i> Refresh</button>
                          </div>
                    </div>
                  </div>
                </div>


                <div class="col-lg-3 col-md-6 col-sm-6">
                  <div class="card card-stats">
                    <div class="card-body ">
                      <div class="row">
                        <div class="col-5 col-md-4">
                          <div class="icon-big text-center icon-warning">
                            <span class="nc"> <img src="../../assets/success.PNG" alt="Girl in a jacket" style="width:90px;height:80px;"> </span>
                          </div></div>
                          <div class="col-7 col-md-8">
                            <div class="numbers">
                              <p class="card-category">Despatched Orders</p>
                              <p class="card-title">{{despatched_order_count}} </p><p></p></div></div></div></div>
                              <div class="card-footer "><hr>
                                <div class="stats">    <button type="button" (click)="view_despatched_orders()" class="btn btn-light"> <i class="fa fa-eye"></i>  View</button>
                                  <button type="button" (click)="get_despatched_order_count_admin()" class="btn btn-light"> <i class="fa fa-refresh"></i> Refresh</button>
                                  </div>
                            </div>
                          </div>
                        </div>





                                <div class="col-lg-3 col-md-6 col-sm-6">
                                  <div class="card card-stats">
                                    <div class="card-body ">
                                      <div class="row">
                                        <div class="col-5 col-md-4">
                                          <div class="icon-big text-center icon-warning">
                                            <span class="nc"> <img src="../../assets/reject.PNG" alt="Girl in a jacket" style="width:90px;height:80px;"> </span>
                                          </div></div>
                                          <div class="col-7 col-md-8">
                                            <div class="numbers">
                                              <p class="card-category">Rejected Orders</p>
                                              <p class="card-title"> {{rejected_order_counts}} </p><p></p></div></div></div></div>
                                              <div class="card-footer "><hr>
                                                <div class="stats">    <button type="button" (click)="view_rejected_orders()" class="btn btn-light"> <i class="fa fa-eye"></i>  View</button>
                                                  <button type="button" (click)="get_rejected_order_count_admin()" class="btn btn-light"> <i class="fa fa-refresh"></i> Refresh</button>
                                                  </div>
                                            </div>
                                          </div>
                                        </div>






                                <div class="col-lg-3 col-md-6 col-sm-6">
                                  <div class="card card-stats">
                                    <div class="card-body ">
                                      <div class="row">
                                        <div class="col-5 col-md-4">
                                          <div class="icon-big text-center icon-warning">
                                            <span class="nc"> <img src="../../assets/man.PNG" alt="Girl in a jacket" style="width:90px;height:80px;"> </span>
                                          </div></div>
                                          <div class="col-7 col-md-8">
                                            <div class="numbers">
                                              <p class="card-category"> Active Customers </p>
                                              <p class="card-title"> {{active_customer_count}} </p><p></p></div></div></div></div>
                                              <div class="card-footer "><hr>
                                                <div class="stats">    <button type="button" (click)="view_active_customers()" class="btn btn-light"> <i class="fa fa-eye"></i>  View</button>
                                                  <button type="button" (click)="get_active_customer_count()" class="btn btn-light"> <i class="fa fa-refresh"></i> Refresh</button>
                                                  </div>
                                            </div>
                                          </div>
                                        </div>











      </div>







      <div class="row" *ngxPermissionsOnly="['User','Shop']">



        <div class="col-lg-3 col-md-6 col-sm-6">
          <div class="card card-stats">
            <div class="card-body ">
              <div class="row">
                <div class="col-5 col-md-4">
                  <div class="icon-big text-center icon-warning">
                    <span class="nc"> <img src="../../assets/dollr.PNG" alt="Girl in a jacket" style="width:90px;height:80px;"> </span>
                  </div></div>
                  <div class="col-7 col-md-8">
                    <div class="numbers">
                      <p class="card-category">Holding Orders</p>
                      <p class="card-title">{{holding_order_count}} </p><p></p></div></div></div></div>
                      <div class="card-footer "><hr>
                        <div class="stats">    <button type="button" (click)="view_holding_orders()" class="btn btn-light"> <i class="fa fa-eye"></i>  View</button>
                            <button type="button" (click)="get_holding_order_count()" class="btn btn-light"> <i class="fa fa-refresh"></i> Refresh</button>
                            </div>
                    </div>
                  </div>
         </div>




        <div class="col-lg-3 col-md-6 col-sm-6">
          <div class="card card-stats">
            <div class="card-body ">
              <div class="row">
                <div class="col-5 col-md-4">
                  <div class="icon-big text-center icon-warning">
                    <span class="nc"> <img src="../../assets/dollr.PNG" alt="Girl in a jacket" style="width:90px;height:80px;"> </span>
                  </div></div>
                  <div class="col-7 col-md-8">
                    <div class="numbers">
                      <p class="card-category">Active Orders</p>
                      <p class="card-title">{{active_order_count}} </p><p></p></div></div></div></div>
                      <div class="card-footer "><hr>
                        <div class="stats">    <button type="button" (click)="view_active_orders()" class="btn btn-light"> <i class="fa fa-eye"></i>  View</button>
                          <button type="button" (click)="get_active_order_count_user()" class="btn btn-light"> <i class="fa fa-refresh"></i> Refresh</button>
                          </div>
                    </div>
                  </div>
                </div>


                <div class="col-lg-3 col-md-6 col-sm-6">
                  <div class="card card-stats">
                    <div class="card-body ">
                      <div class="row">
                        <div class="col-5 col-md-4">
                          <div class="icon-big text-center icon-warning">
                            <span class="nc"> <img src="../../assets/success.PNG" alt="Girl in a jacket" style="width:90px;height:80px;"> </span>
                          </div></div>
                          <div class="col-7 col-md-8">
                            <div class="numbers">
                              <p class="card-category">Despatched Orders</p>
                              <p class="card-title">{{despatched_order_count}} </p><p></p></div></div></div></div>
                              <div class="card-footer "><hr>
                                 <div class="stats">    <button type="button" (click)="view_despatched_orders()" class="btn btn-light"> <i class="fa fa-eye"></i>  View</button>
                                  <button type="button" (click)="get_despatched_order_count()" class="btn btn-light"> <i class="fa fa-refresh"></i> Refresh</button>
                                  </div>
                            </div>
                          </div>
                        </div>


                        <!-- <div class="col-lg-3 col-md-6 col-sm-6">
                          <div class="card card-stats">
                            <div class="card-body ">
                              <div class="row">
                                <div class="col-5 col-md-4">
                                  <div class="icon-big text-center icon-warning">
                                    <span class="nc"> <img src="../../assets/Cancel.PNG" alt="Girl in a jacket" style="width:90px;height:80px;"> </span>
                                  </div></div>
                                  <div class="col-7 col-md-8">
                                    <div class="numbers">
                                      <p class="card-category">Cancelled Orders</p>
                                      <p class="card-title"> {{cancelled_order_count}} </p><p></p></div></div></div></div>
                                      <div class="card-footer "><hr>
                                         <div class="stats">    <button type="button" (click)="view_cancelled_orders()" class="btn btn-light"> <i class="fa fa-eye"></i>  View</button>
                                          <button type="button" (click)="get_cancelled_order_count()" class="btn btn-light"> <i class="fa fa-refresh"></i> Refresh</button>
                                      </div>
                                    </div>
                                  </div>
                        </div> -->




                                <div class="col-lg-3 col-md-6 col-sm-6">
                                  <div class="card card-stats">
                                    <div class="card-body ">
                                      <div class="row">
                                        <div class="col-5 col-md-4">
                                          <div class="icon-big text-center icon-warning">
                                            <span class="nc"> <img src="../../assets/reject.PNG" alt="Girl in a jacket" style="width:90px;height:80px;"> </span>
                                          </div></div>
                                          <div class="col-7 col-md-8">
                                            <div class="numbers">
                                              <p class="card-category">Rejected Orders</p>
                                              <p class="card-title"> {{rejected_order_counts}} </p><p></p></div></div></div></div>
                                              <div class="card-footer "><hr>
                                                <div class="stats">    <button type="button" (click)="view_rejected_orders()" class="btn btn-light"> <i class="fa fa-eye"></i>  View</button>
                                                  <button type="button" (click)="get_rejected_order_count()" class="btn btn-light"> <i class="fa fa-refresh"></i> Refresh</button>
                                              </div>
                                            </div>
                                          </div>
                                        </div>


      </div>










