
<mat-dialog-content class="mat-typography">





<h2> <u>Order Details </u> </h2>  <br>



<h3> Order Person : {{this.hs.order_person_name}}</h3>


        <div class="table-responsive-sm">
          <table class="table">
          <thead>
            <tr>
              <th scope="col">#</th>

              <th scope="col">{{cat_one_name}}</th>
              <th scope="col">{{cat_two_name}}</th>
              <th scope="col">{{cat_three_name}}</th>
              <th scope="col">Product Name</th>
              <th scope="col">Qty</th>
              <th scope="col">Remarks</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of this.hs.selected_order; let k=index" >
              <th scope="row"> {{item.SI_NO}}</th>

              <td> {{item.CAT_NAME }}</td>
              <td> {{item.SUB_CAT_NAME}}</td>
              <td> {{item.SUB_SUB_CAT_NAME }}</td>
              <td> {{item.PRODUCT_NAME }}</td>
              <td> {{item.QTY }}</td>
              <td> {{item.REMARKS }}</td>
            </tr>
          </tbody>
        </table>
        </div>


        <h3>Actions </h3>

        <div *ngxPermissionsOnly="['Admin']">

        <div class="modal-footer">

          <button [disabled]="loading" class="btn btn-primary" (click)="exicute_new_order()" >
             Execute </button>

            <button [disabled]="loading_2" class="btn btn-primary" (click)="revoke_new_order()" >
               Revoke </button>

<!--
            <button [disabled]="loading_3" class="btn btn-primary" (click)="cancel_new_order()" >
             Cancel </button> -->



            <button *ngIf="!rej_flag" [disabled]="loading_4" class="btn btn-primary" (click)="reject_new_order_b()" >
               Reject </button>

               <div *ngIf="rej_flag">

                <mat-form-field class="example-full-width">
                  <mat-label>Rejection reason</mat-label>
                  <textarea  [(ngModel)]="this.hs.rej_reason"    matInput></textarea>
                </mat-form-field>


                <button [disabled]="loading_5" class="btn btn-primary" (click)="reject_new_order()" >
                  Reject </button>


               </div>


        </div>
        </div>



        <div *ngxPermissionsOnly="['User','Shop']">



          <div *ngIf="this.hs.click_status == 0" class="modal-footer" >


            <button [disabled]="loading" class="btn btn-primary" (click)="edit_holding_order()" >
            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>  Edit </button>


            <button [disabled]="loading_2" class="btn btn-primary" (click)="cancel_holding_order()" >
              <span *ngIf="loading_2" class="spinner-border spinner-border-sm mr-1"></span>  Cancel </button>


            <button [disabled]="loading_3" class="btn btn-primary" (click)="submit_holding_order()" >
              <span *ngIf="loading_3" class="spinner-border spinner-border-sm mr-1"></span>  Submit </button>



        </div>









        </div>


</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Close</button>

</mat-dialog-actions>
