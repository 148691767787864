




  <nav aria-label="breadcrumb">
  <ol class="breadcrumb">
    <li class="breadcrumb-item"><a href="/#/dash">Home</a></li>
    <li class="breadcrumb-item"><a href="/#/dash">Order</a></li>
    <li class="breadcrumb-item active" aria-current="page">View  Orders </li>
  </ol>
</nav>










<div *ngIf="this.show_hide" class="container">


<ngx-table [configuration]="configuration" [data]="data" (event)="eventEmitted($event)" [columns]="columns">
	<ng-template let-row>
    <td>
			<span class="font-small display-block">{{row.ORDER_DATE | date}}</span>
    </td>
    <td>
			<span class="font-small display-block">{{row.ID}}</span>
    </td>
    <td>
			<span class="font-small display-block">{{row.ORDER_PERSON_ID}}</span>
    </td>



  	<td>


      <span class="font-small display-block">{{row.SHOP_NAME}}</span>

		</td>

		<td>
			<div>{{row.SHOP_LOCATION}}</div>
		</td>
	</ng-template>
</ngx-table>

</div>










