
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Http_client_Service } from '../Service/http.service';
import { Router } from '@angular/router';

import { browserRefresh } from '../app.component';
import { NgxPermissionsService } from 'ngx-permissions';

@Component({ selector: 'app-dash',
 templateUrl: './dash.html',
 styleUrls: ['./dash.css']
 })
export class DashComponent implements OnInit {


    holding_order_count : number = 0 ;
    active_order_count : number = 0;
    despatched_order_count : number = 0;
    cancelled_order_count : number = 0;
    rejected_order_counts : number = 0;
    active_customer_count : number = 0;

    browserRefresh : boolean = false;


    user_role: any = '';
    user_id : any ;
    status_flag : any;
    click_status :  any ;
    com_id : any;
    user_name : any;



    constructor(private hs:Http_client_Service,private rs: Router,private ps: NgxPermissionsService) {  }

    ngOnInit() {




                    this.user_role  =   localStorage.getItem('user_role');
                    this.user_id  =  localStorage.getItem('user_id');
                    this.com_id  =  localStorage.getItem('com_id');
                    this.user_name = localStorage.getItem('user_name');
                    this.ps.loadPermissions([this.user_role]);




                    if(this.user_role == 'Admin' && this.com_id == 0)
                    {
                      console.log('hi',this.com_id);

                      if(this.com_id == 0)
                      {
                         alert('No Company added to the user Kindly add company Informations');

                         this.rs.navigate(['/settings'])

                      }
                    }
                      else
                      {
                        if(this.user_role == 'Admin')
                        {
                          this.status_flag = 2
                        }
                        else
                        {
                          this.status_flag = 0
                        }



                        if(this.user_role == 'Admin')
                        {
                             this.get_active_customer_count();
                             this.get_active_order_count_admin();
                             this.get_despatched_order_count_admin();
                             this.get_cancel_order_count_admin();
                             this.get_rejected_order_count_admin();




                        }
                        else
                        {
                             this.get_active_order_count_user();
                             this.get_despatched_order_count();
                             this.get_holding_order_count();
                             this.get_cancelled_order_count();
                             this.get_rejected_order_count();
                        }
                      }


               }

            start_over()
                {





                }


               view_orders()
               {
                 if(this.hs.user_role == 'Admin')
                 {
                   this.status_flag = 2
                  localStorage.setItem('status_flag', this.status_flag);
                   this.hs.status_flag = 2
                 }
                 else
                 {
                  this.status_flag = 0
                  localStorage.setItem('status_flag', this.status_flag);
                  this.hs.status_flag = 0
                 }

                 this.rs.navigate(['/order-view-active']);
               }


               get_active_customer_count()
               {

                         this.hs.get_active_customer_count(this.com_id)
                         .subscribe((jsonData:any) => {  this.active_customer_count = jsonData.result;
                         },(err:any) => console.error(err),
                         );
               }


               get_active_order_count_admin()
               {

                         this.hs.get_active_order_count_admin(this.com_id)
                         .subscribe((jsonData:any) => {   this.active_order_count = jsonData.result;
                         },(err:any) => console.error(err),
                         );
               }


               get_despatched_order_count_admin()
               {
                    this.hs.get_despatched_order_count_admin(this.user_id)
                    .subscribe((jsonData:any) => {  this.despatched_order_count = jsonData.result;
                    },(err:any) => console.error(err),
                    );
               }

               get_cancel_order_count_admin()
               {
                this.hs.get_cancelled_order_count_admin(this.user_id)
                .subscribe((jsonData:any) => {  this.cancelled_order_count =   jsonData.result;
                },(err:any) => console.error(err),
                );
               }

               get_rejected_order_count_admin()
               {
                this.hs.get_rejected_order_count_admin(this.user_id)
                .subscribe((jsonData:any) => {  this.rejected_order_counts =   jsonData.result;
                },(err:any) => console.error(err),
                );
               }



              get_active_order_count_user()
              {
                this.hs.get_active_order_count(this.user_id)
                .subscribe((jsonData:any) => { this.active_order_count =  jsonData.result;
                },(err:any) => console.error(err),

                );
              }


              get_despatched_order_count()
              {
               this.hs.get_despatched_order_count(this.user_id)
               .subscribe((jsonData:any) => { this.despatched_order_count = jsonData.result;
               },(err:any) => console.error(err),
               );


              }


              get_holding_order_count()
              {

               this.hs.get_holding_order_count(this.user_id)
               .subscribe((jsonData:any) => { this.holding_order_count = jsonData.result;
               },(err:any) => console.error(err),
               );

              }


              get_cancelled_order_count()
              {

                   this.hs.get_cancelled_order_count(this.user_id)
                   .subscribe((jsonData:any) => { this.cancelled_order_count = jsonData.result
                   },(err:any) => console.error(err),
                   );

              }


              get_rejected_order_count()
              {
                     this.hs.get_rejected_order_count(this.user_id)
                     .subscribe((jsonData:any) => { this.rejected_order_counts = jsonData.result
                     },(err:any) => console.error(err),
                     );

              }









    view_holding_orders()
    {

      this.status_flag = 0;


     localStorage.setItem('status_flag', this.status_flag);
     this.rs.navigate(['/order-view-active'])
    }

    view_active_orders()
    {

      this.hs.status_flag = 2;
      this.status_flag = 2;
      localStorage.setItem('status_flag', this.status_flag);
      localStorage.setItem('click_status', this.click_status);
      this.rs.navigate(['/order-view-active'])
    }

    view_despatched_orders()
    {
      this.hs.status_flag = 3;
      this.status_flag = 3;
     localStorage.setItem('status_flag', this.status_flag);
     localStorage.setItem('click_status', this.click_status);
      this.rs.navigate(['/order-view-active'])
    }

    view_cancelled_orders()
    {
      this.hs.status_flag = 4;
      this.status_flag = 4;
     localStorage.setItem('status_flag', this.status_flag);
     localStorage.setItem('click_status', this.click_status);
      this.rs.navigate(['/order-view-active'])
    }
    view_rejected_orders()
    {

      this.hs.status_flag = 5;
      this.status_flag = 5;
      localStorage.setItem('status_flag', this.status_flag);
      localStorage.setItem('click_status', this.click_status);
      this.rs.navigate(['/order-view-active'])

    }




    view_active_customers()
    {

      this.rs.navigate(['/view_active_customers'])


    }

    log_out()
    {
      localStorage.clear();
      this.rs.navigate(['/login']);
    }









}
