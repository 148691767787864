

  export class _order_master {
    constructor(
    public  ID:number,
    public  SHOP_NAME:string,
    public  SHOP_ID:number,
    public  SHOP_LOCATION:string,
    public  ORDER_DATE:string,
    public  STATUS_FLAG : number




    ) {} }


    export class _order_slave {
      constructor(
      public  ID : number,
      public  PRODUCT_NAME : string,
      public  BRAND:string,
      public  COLOR:string,
      public  ORDER_NO:number,
      public  PRO_ID:number,
      public  QTY:number,
      public  SIZE:string,
      public  REMARKS : string,
      public  SHOP_ID : number,
      public  SI_NO : number




      ) {} }


      export class User_info {
        constructor(
        public  user_id:number,
        public  user_role:string,
        public  status_flag:number,





        ) {} }





