
<nav aria-label="breadcrumb" *ngIf="!this.hs.is_shop_updation">
  <ol class="breadcrumb">
    <li class="breadcrumb-item"><a href="/#/dash">Home</a></li>
    <li class="breadcrumb-item"><a href="/#/dash">settings</a></li>
    <li class="breadcrumb-item active" aria-current="page">Add Shop</li>
  </ol>
</nav>

<nav aria-label="breadcrumb" *ngIf="this.hs.is_shop_updation">
  <ol class="breadcrumb">
    <li class="breadcrumb-item"><a href="/#/dash">Home</a></li>
    <li class="breadcrumb-item"><a href="/#/dash">settings</a></li>
    <li class="breadcrumb-item active" aria-current="page">Update Shop</li>
  </ol>
</nav>



<form *ngIf="!this.hs.is_shop_updation" [formGroup]="shopForm">
<section>

  <br>
  <div class="container">
  <div class="form-row">

    <div class="form-group col-md-3 col-12"></div>

    <div class="form-group col-md-6 col-12">
      <label for="input_product_1">Shop Name</label>
      <input type="text" formControlName="SHOP_NAME" class="form-control" id="input_shopname_1">
      <label for="input_product_1">Location</label>
      <input type="text" formControlName="SHOP_LOCATION" class="form-control" id="input_shoplocation_1">
      <label for="input_product_1">GSTIN</label>
      <input type="text" formControlName="SHOP_GSTIN" class="form-control" id="input_shopgst_1">
      <label for="input_product_1">Phone Number</label>
      <input type="text" formControlName="SHOP_PHONE" class="form-control" id="input_shopphone_1">
      <label for="input_product_1">Email</label>
      <input type="text" formControlName="SHOP_EMAIL" class="form-control" id="input_shopemail_1"> <br>


      <button [disabled]="!shopForm.valid" type="button"(click)="addshop()" class="btn btn-primary btn-lg btn-block">Add</button>
    </div>

    <div class="form-group col-md-3 col-12"></div>

  </div>


  </div>
</section>

</form>


<form  *ngIf="this.hs.is_shop_updation" [formGroup]="shop_updation_Form">
  <section>

    <br>
    <div class="container">
    <div class="form-row">

      <div class="form-group col-md-3 col-12">    </div>
      <div class="form-group col-md-6 col-12">
        <label for="input_product_1">Shop Search</label>

        <input  (valueChanged)="search_shop($event)"
         auto-complete formControlName="ID" list-formatter=" SHOP_NAME -- SHOP_LOCATION"
        loading-text = "loading"
        display-property-name="ID" class="form-control" [source]="mySource"/>

        <label for="input_product_1">Shop Name</label>
        <input type="text" formControlName="SHOP_NAME"  class="form-control">

        <label for="input_product_1">Location</label>
        <input type="text" formControlName="SHOP_LOCATION" class="form-control">
        <label for="input_product_1">GSTIN</label>
        <input type="text" formControlName="SHOP_GSTIN" class="form-control">
        <label for="input_product_1">Phone Number</label>
        <input type="number" formControlName="SHOP_PHONE" class="form-control">
        <label for="input_product_1">Email</label>
        <input type="text" formControlName="SHOP_EMAIL" class="form-control">


      <br>

      <button [disabled]="!shop_updation_Form.valid" type="button"(click)="update_shop()" class="btn btn-primary btn-lg btn-block">Update</button> <br>
      <button [disabled]="!shop_updation_Form.valid" type="button"(click)="delete_shop()" class="btn btn-danger btn-lg btn-block">Delete</button>
    </div>


    <div class="form-group col-md-3 col-12">    </div>


    </div>
    </div>
  </section>

  </form>






