import { Component, OnInit } from '@angular/core';
import {FormBuilder,FormControl, FormGroup, Validators} from '@angular/forms';
import { Http_client_Service } from '../../Service/http.service';
@Component({
  selector: 'app-add-shop',
  templateUrl: './add-shop.html',
  styleUrls: ['./add-shop.css']
})
export class Add_shopComponent implements OnInit {

  myData : any;
  mySource : any [] = [];
  com_id : any;
  shopForm = new FormGroup({

    SHOP_NAME: new FormControl('',Validators.required),
    SHOP_GSTIN: new FormControl(''),
    SHOP_LOCATION: new FormControl('',Validators.required),
    SHOP_PHONE: new FormControl('', [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]),
    SHOP_EMAIL: new FormControl(''),
    COM_ID :  new FormControl(''),

  });

  shop_updation_Form = new FormGroup({

    SHOP_NAME: new FormControl('',Validators.required),
    SHOP_GSTIN: new FormControl(''),
    SHOP_LOCATION: new FormControl(''),
    SHOP_PHONE: new FormControl(''),
    SHOP_EMAIL: new FormControl(''),
    ID: new FormControl(''),

  });

  constructor(public hs: Http_client_Service ) {


   }




  ngOnInit(): void {

    this.get_all_shop_details();

  }

  get_all_shop_details()
  {
    this.com_id  =  localStorage.getItem('com_id');
    this.hs.get_all_shop_details(this.com_id)
    .subscribe((jsonData:any) => { this._get_all_shop_details(jsonData)
            },(err:any) => console.error(err),

            );
  }

  _get_all_shop_details(shops:any)
  {
    this.mySource = shops;
  }

  addshop()
  {

    console.log(this.shopForm.value);

    this.com_id  =  localStorage.getItem('com_id');
    this.shopForm.patchValue({ COM_ID : this.com_id })
    this.hs.add_new_shop(this.shopForm.value)
    .subscribe((jsonData:any) => { this._get_res_2(jsonData)
            },(err:any) => console.error(err),

            );
  }

  delete_shop()
  {

    console.log(this.shop_updation_Form.value);
    this.hs.delete_exsiting_shop(this.shop_updation_Form.value)
    .subscribe((jsonData:any) => { this._get_res_3(jsonData)
            },(err:any) => console.error(err),

            );

  }

  update_shop()
  {

    console.log(this.shop_updation_Form.value);
    this.hs.update_exsiting_shop(this.shop_updation_Form.value)
    .subscribe((jsonData:any) => { this._get_res_3(jsonData)
            },(err:any) => console.error(err),

            );

  }


  _get_res_2(json :any)
  {
    alert(json.msg);
    this.shopForm.reset();

  }

  _get_res_3(json :any)
  {
    alert(json.msg);
    this.shop_updation_Form.reset();

    this.get_all_shop_details();

  }


  search_shop(ev:any)
    {

      console.log('ev',ev)
        this.shop_updation_Form.patchValue({SHOP_NAME : ev.SHOP_NAME , SHOP_LOCATION : ev.SHOP_LOCATION ,
           SHOP_GSTIN :   ev.SHOP_GSTIN  , SHOP_PHONE :  ev.SHOP_PHONE , SHOP_EMAIL: ev.SHOP_EMAIL , ID : ev.ID })


  }




 }


