import { Component, OnInit,ViewChild } from '@angular/core';
import { Http_client_Service } from '../../Service/http.service';
import { _shop } from './model'

@Component({
  selector: 'app-view-shop',
  templateUrl: './view.html',
  styleUrls: ['./view.css']
})
export class View_shopComponent implements OnInit {


  _Shop: _shop[] = [];

  view : boolean = true;
  com_id : any;



  constructor(public hs: Http_client_Service) {


  }

  ngOnInit(): void {

    this.get_all_shop_details();


  }

  get_all_shop_details() {

    this.com_id = localStorage.getItem('com_id');
    this.hs.get_all_shop_details(this.com_id)
      .subscribe((jsonData: any) => {
        this._get_all_shop_details(jsonData)
      }, (err: any) => console.error(err),);
  }

  _get_all_shop_details(shops: any) {

    this._Shop = shops
    console.log('shop_list', this._Shop);
    this.view = false;
  }








}


